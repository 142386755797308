.modern_switcher {
  display: flex;
  align-items: center;
  gap: 5px;
  width: auto;
  border: 1px solid grey;
  border-radius: 8px;
  padding: 4px 10px;
  background-color: #fff;
  white-space: nowrap;
  width: fit-content;
  font-size: 14px;
  cursor: pointer;
  position: relative;
}

.modern_switcher.inactive {
  border: 1px solid var(--color-border-table);
  color: var(--color-secondary)
}
.disabled {
  background: #e1e1e1;
}
.modern_switcher.active {
  border: 1px solid var(--color-primary-blue);
  color: var(--color-primary-blue);
}

.toggle_modern {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.modern_checkmark,
.modern_checkmarked {
  position: absolute;
  top: 7px;
  left: 8px;
  border: 1px solid #9a9191;
  height: 16px;
  width: 16px;
  border-radius: 50%; /* Hace que el círculo sea redondeado */
}

.toggle_modern:checked + .modern_checkmarked {
  border: none;
  background-color: var(
    --color-primary-blue
  ); /* Cambia el color de fondo cuando el checkbox está marcado */
}

/* Estilo adicional para mejorar la apariencia */
.modern_checkmarked:after {
  content: '';
  position: absolute;
  display: none;
}

.toggle_modern:checked + .modern_checkmarked:after {
  display: block;
}

.modern_checkmarked:after {
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.modern_text {
  margin-left: 18px !important;
}
