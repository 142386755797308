.amount-container {
  border-radius: 1rem;
  text-align: center;
  border: 1px solid #ddd;
  height: 25px;
}

.restaurant-border {
  padding-bottom: 10px ;
  border-bottom: 1px solid #E1E1E1;
}
.restaurant-totals {
  padding: 0 30px;
}
.border-total  div {
  padding-top: 0.5rem;
  border-top: 1px solid #E1E1E1;;
}