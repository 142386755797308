.timeline.wire::before,
.timeline.wire::after {
  content: none;
}

.timeline .timelineItem {
  position: relative;
  padding-top: 1.5rem;
}

.timeline .timelineItem::after {
  content: '';
  position: absolute;
  border-left: 1px solid #ccc;
  left: 8px;
  height: 100%;
  top: 0;
}

.timeline .timelineItem:first-child {
  padding-top: 0;
}

.timeline .timelineItem:first-child::after {
  top: 50%;
}

.timeline .timelineItem:last-child::after {
  bottom: 40%;
  top: unset;
}

.timeline .timelineItem:first-child:last-child::after {
  border: none;
}

.timeline .dot {
  width: 14px;
  height: 14px;
  position: absolute;
  background-color: #17b26a;
  border-radius: 9999px;
  border: 3px #dcfae6 solid;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 8px;
  z-index: 10;
}

.timeline .dot.alertDot {
  background-color: #b42318;
  border-color: #fecdca;
}
