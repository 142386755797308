.sign-header-container {
  background-color: #000;
  color: #fff;
  padding: 0.75rem 1rem;
  z-index: 100;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media (min-width: 769px) {
  .header-container .navbar-brand {
    min-width: 17.5rem;
  }
}
