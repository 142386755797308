.card-reservation {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 275px;
  position: relative;
  padding: 10px 0;
}

.card-reservation.active {
  border-color: var(--color-primary-new);
}

.card-reservation-text {
    color: #545969
}

.card-reservation-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  text-align: center;
  font-size: 12px;
  color: #545969;
  background: #fff;
  padding: 0 5px;
}
.card-reservation.active .card-reservation-title, .card-reservation.active .card-reservation-text {
  font-weight: 600;
  color: var(--color-primary-new);
}
