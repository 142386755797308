.notification {
  background: #f9fafb;
  border: 1px solid #eaecf0;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  font-weight: 500;
  font-size: 12px;
  margin-left: 0.5rem;
  line-height: 18;
}

.badgeNotification {
  margin-left: 10px;
  background-color: var(--color-primary-blue);
  color: white;
  border-radius: 50%;
  font-size: 12px;
  height: 7px;
  width: 7px;
}
