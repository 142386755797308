.custom-tooltip {
  position: relative;
  display: inline-block;
}
.custom-tooltip .tooltiptext {
  visibility: hidden;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  color: var(--color-text);
  padding: 5px 10px;
  font-weight: bold;
  position: absolute;
  background-color: var(--color-background-app);
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  opacity: 0;
  margin-top: -35px;
  transition: opacity 0.3s;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltipCard {
  color: var(--color-text);
  background-color: var(--color-background-app);
  padding: 5px 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  font-size: 0.75rem;
}

.tooltipBlackCard {
  color: var(--color-text);
  background-color: var(--color-background-app);
  padding: 5px 10px;
  font-weight: bold;
  text-align: center;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  font-size: 0.75rem;
}

.custom-tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.new-tooltip {
  background: #0c111d;
  color: #d0d5dd;
  border-radius: 10px;
  font-size: 12px;
  padding: 12px;
  box-shadow: 0px 12px 16px -4px #10182814;
}
