/* mediaSelector.module.css */

.containerMedia {
  background-color: #171717;
  border-radius: 15px;
  height: 100%;
  max-height: 300px;
  color: white;
  position: relative;
  overflow: hidden; /* Para asegurarse de que el pseudo-elemento no se desborde */
}
.containerMedia.selectedFile {
  border: 3px solid #675dff;
}

.containerMedia::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent);
  z-index: 1;
}
.notSelected {
  outline: none;
  border: 1px #f3f3f3 solid;
  border-radius: 0.8rem;
  font-size: 14px;
  padding: 16px 24px;
  color: #b6bcc5;
  background-color: #f3f3f3;
}
.error,
.notSelected.error {
  border: 3px solid #ef5350;
}
.title {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 2; /* Asegura que esté por encima del pseudo-elemento */
}
.checkPreview {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;
}
.containerFile {
  height: 100%;
  width: 100%;
}
.containerFile img,
.containerFile video {
  background-color: #171717 !important;
  border: none;
  width: 100%;
  border-radius: 15px;
}
.containerList {
  height: auto;
  width: auto;
  max-height: 300px;
  overflow-y: scroll;
}
.mediaItem {
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.mediaItem.notAvailable {
  opacity: 0.5;
}
.mediaItem:hover {
  background-color: #d9d9d9;
}
.mediaItem.selected {
  background-color: #d9d9d9;
}
.mediaItem img,
.mediaItem video {
  background-color: #171717;
  border: none;
  height: 60px !important;
  width: 60px !important;
  border-radius: 10px;
}

/* Check */
.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative;
}

/* Estilo del checkmark adaptado */
.circle .checkmark {
  height: 12px; /* Ajusta el tamaño del checkmark dentro del círculo */
  width: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  display: none; /*2 Por defecto está oculto */
  position: absolute;
  transform: rotate(45deg);
  top: 3px;
}

.checked {
  background-color: #675dff;
  border-color: #675dff;
}

.checked .checkmark {
  display: block;
}
