.border-bottom {
  border-bottom: 1px solid #eaecf0;
}

.margin-reservation {
  margin-bottom: 2.1875rem !important;
}
/* .btn-accept {
  background-color: #7f56d9;
  color: white;
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
}

.btn-block {
  background-color: #d92d20;
  color: white;
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
  line-height: 28px;
  word-wrap: break-word;
} */

/* .timeline.reservation::before,
.timeline.reservation::after {
  content: none;
}

.timeline .timeline-item {
  position: relative;
  padding-top: 2rem;
}

.timeline .timeline-item::after {
  content: '';
  position: absolute;
  border-left: 1px solid #ccc;
  left: 0;
  height: 100%;
  top: 0;
}

.timeline .timeline-item:first-child {
  padding-top: 0;
}

.timeline .timeline-item:first-child::after {
  top: 50%;
}

.timeline .timeline-item:last-child::after {
  bottom: 33%;
  top: unset;
}

.timeline .dot {
  width: 14px;
  height: 14px;
  position: absolute;
  background-color: #17b26a;
  border-radius: 9999px;
  border: 3px #dcfae6 solid;
  top: 50%;
  transform: translate(0, -50%);
  left: -6px;
  z-index: 10;
}

.timeline .dot.alert-dot {
  background-color: #b42318;
  border-color: #fecdca;
} */
