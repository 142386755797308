.btnFilter {
  border: 1px solid #d0d5dd;
  border-radius: 6px;
  padding: 0.2rem 1rem;
  color: #344054;
  font-weight: 700;
  height: 40px;
  width: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  background-color: var(--color-background-app);
  transition: background-color 0.2s ease-in-out;
  position: relative;
}

.btnFilterDashed {
  border: 1px dashed var(--color-border-table);
  border-radius: 9999px;
  padding: 2px 10px 2px 8px;
  color: #344054;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  background-color: var(--color-background-app);
  transition: background-color 0.2s ease-in-out;
  position: relative;
}

.content-filter {
  max-height: 200px;
  height: 100%;
  overflow-y: auto;
}
.btnFilter:hover {
  background-color: var(--hover);
}
/* Estilos para el modal */
.filter-modal {
  width: 250px;
  background-color: var(--color-background-app);
  color: var(--color-text);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  margin-top: 10px;
}

.filter-modal h4 {
  font-weight: 700;
}
.filter-modal ul {
  font-weight: 500;
}
.option-selected {
  color: var(--color-primary-blue);
  font-weight: 700;
}
@media (max-width: 768px) {
  .btnFilter {
    background-color: transparent;
    width: fit-content;
    justify-content: start;
    max-width: 300px;
  }
}
