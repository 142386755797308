.loaderDiv {
  margin-top: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;
}

.text-spinner {
  color: var(--color-primary-blue);
}

.opacityDiv {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgb(255 245 245 / 58%);
  z-index: 998;
}
