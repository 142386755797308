.card.selectCard {
  border: 1px solid var(--color-border-table);
}

.card.selectCard.selected,
.card.selectCard:hover {
  border: 1px solid var(--color-primary-new);
}

.card.selectCard:hover:not(.selectCard.selected) {
  border: 1px solid var(--color-primary-new);
}

.title-select-card {
  color: var(--color-primary);
  font-size: 16px;
  font-weight: 600;
}
