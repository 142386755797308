.alert {
  box-shadow: none !important;
  padding: 0.3rem !important;
  margin: 0 !important;
}
.alert-success {
  background-color: var(--bg-green) !important;
  border: 1px solid var(--border-color-green) !important;
  color: var(--color-green) !important;
}
.alert-error {
  background-color: var(--bg-red) !important;
  border: 1px solid var(--border-color-red) !important;
  color: var(--color-red) !important;
}
.alert-warning {
  background-color: var(--bg-orange) !important;
  border: 1px solid var(--border-color-orange) !important;
  color: var(--color-orange) !important;
}
.alert-info {
  background-color: var(--bg-blue) !important;
  border: 1px solid var(--border-color-blue) !important;
  color: var(--color-blue);
}

.alert-notification {
  background-color: #fbf8c9 !important;
  border: 1px solid #eae7c5 !important;
  color: #794916 !important;
}
