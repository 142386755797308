.selector {
  position: relative;
  background-color: #fff;
  color: #101828;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  line-height: 24px;
  padding: 10px 14px 10px 14px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px #1018280d;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  cursor: pointer;
}
.menuItemContainer:hover {
  background-color: var(--hover);
}

.companySelector:hover {
  background-color: #f5f6f8;
}
.btnCompanySelector {
  height: auto;
  padding: 3px;
}
.companyIcon {
  margin-left: 1rem;
}
