.progress-bar,
.filled-progress {
  height: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.content-progress-bar {
  border: 1px dashed;
  border-radius: 5px;
  padding: 15px;
}
