.sidebar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 17rem;
  position: fixed;
  top: 0;
  bottom: 0;
  border-right: 1px solid #EAECF0;
  background-color: var(--color-background-app);
  transition: background-color 0.5 ease-in;
  box-shadow: none;
}

.sidebar-light {
  color: #333;
}

.sidebar-content {
  position: static !important;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
}

.sidebar-section {
  position: static !important;
}
.sidebar-section-body {
  position: relative;
  padding: 1.25rem 1.25rem;
}

.sidebar-user-material-footer > a {
  padding: 0.75rem 1.25rem;
  display: block;
  color: #fff;
  background-color: #fdfefe;
  transition: background-color ease-in-out 0.3s;
}

.sidebar-user-material-footer > a:hover {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.25);
  text-decoration: none;
}
.sidebar-mobile-expanded.sidebar-main,
.sidebar-mobile-expanded.sidebar-secondary {
  left: 0;
}

.nav-sidebar {
  -ms-flex-direction: column;
  flex-direction: column;
}

@media (max-width: 991.98px) {
  .sidebar-expand-lg {
    z-index: 1038;
    transition: left ease-in-out 0.15s, right ease-in-out 0.15s;
  }
}

@media (min-width: 992px) {
  .sidebar-expand-lg.sidebar-main {
    z-index: 99;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) {
    width: 3.5rem;
    overflow: hidden;
  }

  .sidebar-expand-lg {
    position: static;
  }

  .sidebar-expand-lg.sidebar-main-resized {
    left: 0;
    transition: none;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .sidebar-user-material
    .sidebar-section-body
    > .d-flex
    > div {
    display: none;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .sidebar-user-material
    .sidebar-section-body
    > .text-center {
    display: none;
  }

  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-user-material-footer {
    display: none;
  }

  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header {
    padding-left: 0;
    padding-right: 0;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    display: -ms-flexbox;
    display: flex;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-title {
    display: none !important;
  }

  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > .icon-menu {
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
    position: static;
    display: block !important;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-item .icon-collapse {
    display: none !important;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-item span {
    display: none !important;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-item
    .company-selector-dropdown {
    display: none !important;
  }

  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-section-body img {
    width: 2.25rem;
    height: 2.25rem;
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
}
.sidebar-expand-lg.sidebar-main-resized.sidebar-main-unfold {
  width: 17rem;
  /* transition: all 0.1s ease; */
  z-index: 1022;
  box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.2);
}
.sidebar-expand-lg.sidebar-main-resized {
  position: absolute;
  left: 0;
  /* transition: all 0.1s ease; */
}

.sidebar-space-y {
  padding-top: 20px;
  padding-bottom: 20px;
}

.sidebar-space-y:empty {
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-width: 1400px) {
  .sidebar-space-y {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}