.card {
  border-radius: 12px;
  transition: all 0.3s ease;
  width: auto;
  padding: 1.5rem;
}

.card:hover {
  background-color: rgba(208, 179, 255, 0.11);
  cursor: pointer;
}

.configCard {
  height: 100%;
}

.iconTitleContainer {
  display: flex;
  align-items: flex-start; 
}

.configContentIcon {
  width: 32px; 
  height: 32px; 
  padding: 6px;
  background-color: #f7f7ff;
  border: none;
  border-radius: 8px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titleTextContainer {
  display: flex;
  flex-direction: column; 
}

.configTitle {
  color: #675dff;
  font-size: var(--font-size-base);
  font-weight: var(--font-weight-semibold);
  text-decoration: none;
  margin: 0; 
}

.configTitle:hover {
  color: #675dff;
}

.configText {
  font-size: var(--font-size-xs);
  font-weight: var(--font-weight-normal);
  line-height: var(--line-height-xs);
  color: var(--color-secondary);
  margin-top: 4px; 
}
