/* Actions Reservations Dropdwons */
#dropdownActionReservations .dropdown {
  position: relative;
}
.container-reservation-actions .actionReservationsList {
  position: absolute;
  z-index: 9999;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  padding: 0;
  margin: 0;
}

/* Logout Dropdwons */
#downloadPdfDropdown .dropdown {
  position: relative;
}
#downloadPdfDropdown .dropdown-menu {
  right: 2px !important;
  left: auto;
}
#logoutDropdown .dropdown, #notification .dropdown {
  position: relative;
}
#logoutDropdown .dropdown-menu {
  right: 0 !important;
  left: auto;
}

#notification .dropdown-menu {
  right: -20px !important;
  left: auto;
}


#notification .dropdown-menu {
  width: 350px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

/* Compnay Selector Dropdwons */
#marketSelector.dropdown {
  position: static !important;
}
#marketSelector .dropdown-menu {
  margin: 10px !important;
  width: 15rem;
  top: 50px;
}
#marketSelector .dropdown-item {
  flex-direction: column;
  align-items: start;
}
#organizationSelector.dropdown {
  position: static !important;
}
#organizationSelector .dropdown-menu {
  margin: 10px !important;
  width: 15rem;
  top: 50px;
  max-height: 200px;
  overflow-y: scroll;
}
#organizationSelector .dropdown-item {
  flex-direction: column;
  align-items: start;
}
/* Actions Dropdwons */
#dropdownAction.dropdown {
  position: relative;
}
#dropdownAction .dropdown-menu {
  left: auto !important;
  right: 0px !important;
}

#marketSelector .dropdown-toggle {
  width: 100%;
}
#organizationSelector .dropdown-toggle {
  width: 100%;
}
