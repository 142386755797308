@charset "UTF-8";
/* ------------------------------------------------------------------------------
 *
 *  # Core layout
 *
 *  Content area, sidebar, page header and boxed layout styles
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Global configuration
 *
 *  Here you can change main theme, enable or disable certain components and
 *  optional styles. This allows you to include only components that you need.
 *
 *  'true'  - enables component and includes it to main CSS file.
 *  'false' - disables component and excludes it from main CSS file.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom Limitless functions
 *
 *  Utility mixins and functions for evalutating source code across our variables, maps, and mixins.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Custom template mixins
 *
 *  All custom mixins are prefixed with "ll-" to avoid conflicts
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Default Bootstrap variable overrides
 *
 *  Variables should follow the `$component-state-property-size` formula for
 *  consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.
 *  Also includes custom variables, all marked with "!default" flag.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Additional variables
 *
 *  Mainly 3rd party libraries and additional variables for default
 *  Bootstrap components.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Mixins
 *
 *  Import Bootstrap mixins with overrides
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Buttons mixin
 *
 *  Override and extend default buttons mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Caret mixin
 *
 *  Override and extend default cared mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Background mixin
 *
 *  Override and extend default background mixin.
 *
 * ---------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------------
 *
 *  # Main content layout
 *
 *  Styles for main structure of content area
 *
 * ---------------------------------------------------------------------------- */
html {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  height: 100%;
  overflow: hidden;
  background-color: var(--color-background-app) !important;
}

.cursor-default {
  cursor: default !important;
}

.page-content {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.content-wrapper {
  display: -ms-flexbox;
  overflow-x: hidden !important;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  min-width: 0;
  margin: 20px;
}

@media screen and (max-width: 480px) {
  .content-wrapper {
    padding-bottom: calc(100px + env(safe-area-inset-bottom)) !important;
  }
}

.cursor-normal {
  cursor: text;
}

.img-table {
  height: 40px;
  width: 40px;
  filter: grayscale(100%);
  border: 1px solid var(--color-border-table);
  border-radius: 100px !important;
  background-color: var(--color-background-app);
}

.img-table.no-filter {
  filter: none;
}

.content {
  padding: 1.25rem 1.25rem;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.content::after {
  display: block;
  clear: both;
  content: '';
}

.content-inner {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
@media (prefers-reduced-motion: no-preference) {
  .content-inner {
    scroll-behavior: smooth;
  }
}

.layout-static,
.layout-static body,
.layout-static .page-content {
  overflow: visible;
}

.btn-to-top {
  position: absolute;
  bottom: 1.25rem;
  right: 1.25rem;
  -ms-flex-item-align: end;
  align-self: flex-end;
  opacity: 0;
  visibility: hidden;
  z-index: 999;
  transition: all 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn-to-top {
    transition: none;
  }
}
.btn-to-top-visible {
  visibility: visible;
  opacity: 1;
}
.navbar + .btn-to-top {
  bottom: 5.125rem;
}
.navbar + .navbar + .btn-to-top {
  bottom: 9rem;
}

/* ------------------------------------------------------------------------------
 *
 *  # Page header
 *
 *  Page header components and color options
 *
 * ---------------------------------------------------------------------------- */
.page-title {
  padding: 2rem 0;
  position: relative;
}
.page-title small {
  display: inline-block;
  margin-left: 0.625rem;
}
.page-title small:before {
  content: '/';
  margin-right: 0.875rem;
}
.page-title small.d-block {
  margin-left: 0;
}
.page-title small.d-block:before {
  content: none;
}
.page-title small.d-block,
.page-title .breadcrumb {
  margin-left: 1.875rem;
}
.page-title h1,
.page-title h2,
.page-title h3,
.page-title h4,
.page-title h5,
.page-title h6 {
  margin: 0;
}

.page-header-content {
  position: relative;
  padding: 0 1.25rem;
}

.page-header-light {
  background-color: #fff;
}
.page-header-light.has-cover {
  background: url('../assets/images/backgrounds/seamless.png');
}

.page-header-dark {
  background-color: #273246;
  color: #fff;
}
.page-header-dark > .breadcrumb > li > a,
.page-header-dark > .breadcrumb > li + li:before,
.page-header-dark .page-header-content .breadcrumb > li > a,
.page-header-dark .page-header-content .breadcrumb > li + li:before {
  color: rgba(255, 255, 255, 0.9);
}
.page-header-dark > .breadcrumb > li > a:hover,
.page-header-dark > .breadcrumb > li > a:focus,
.page-header-dark .page-header-content .breadcrumb > li > a:hover,
.page-header-dark .page-header-content .breadcrumb > li > a:focus {
  color: #fff;
  opacity: 1;
}
.page-header-dark > .breadcrumb > .active,
.page-header-dark .page-header-content .breadcrumb > .active {
  color: rgba(255, 255, 255, 0.5);
}
.page-header-dark.has-cover {
  background: url('../assets/images/login_cover.jpg');
  background-size: cover;
}

.page-header-light,
.page-header-dark {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.page-header-static {
  z-index: 1021;
}

/* ------------------------------------------------------------------------------
 *
 *  # Sidebar layouts
 *
 *  Sidebar components, main navigation and sidebar itself
 *
 * ---------------------------------------------------------------------------- */
.sidebar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 250px;
  position: fixed;
  top: 0;
  bottom: 0;
  box-sizing: content-box;
}

.sidebar-main,
.sidebar-secondary {
  left: -20.625rem;
  /* box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35); */
}

.sidebar-right {
  right: -20.625rem;
  /* box-shadow: -0.25rem 0 1rem rgba(0, 0, 0, 0.35); */
}

.sidebar-content {
  position: static !important;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

.sidebar-section {
  position: relative;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.sidebar-section .form-group:last-child {
  margin-bottom: 0;
}

.sidebar-section-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 1.25rem;
}

.sidebar-section-body {
  padding: 1rem;
}

.sidebar-resize-show {
  display: none;
}

.btn-sidebar-expand {
  display: none;
  border-radius: 0;
  padding: 0.3125rem;
}

.sidebar-mobile-expanded.sidebar-main,
.sidebar-mobile-expanded.sidebar-secondary {
  left: 0;
}

.sidebar-mobile-expanded.sidebar-right {
  right: 0;
}

.sidebar-mobile-expanded.sidebar-component {
  display: block;
}

.sidebar-user img {
  height: 2.375rem;
  width: 2.375rem;
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
}

.nav-sidebar {
  -ms-flex-direction: column;
  flex-direction: column;
}
/* .nav-sidebar .nav-item:not(.nav-item-header):first-child {
  padding-top: 0.5rem;
}
.nav-sidebar .nav-item:not(.nav-item-header):last-child {
  padding-bottom: 0.5rem;
} */
/* .nav-sidebar .nav-item:not(.nav-item-divider) {
  margin-bottom: 1px;
} */
.nav-sidebar .nav-item:not(.nav-item-divider):last-child {
  margin-bottom: 0;
}
.nav-sidebar > .nav-item > .nav-link {
  font-weight: 500;
}
.nav-sidebar .nav-link {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 0.75rem 1.25rem;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .nav-sidebar .nav-link {
    transition: none;
  }
}
.nav-sidebar .nav-link i {
  margin-right: 1.25rem;
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
  top: 0;
}
.nav-sidebar .nav-link .badge {
  transition: background-color ease-in-out 0.15s, border-color ease-in-out 0.15s;
}
@media (prefers-reduced-motion: reduce) {
  .nav-sidebar .nav-link .badge {
    transition: none;
  }
}
.nav-sidebar .nav-link.disabled,
.nav-sidebar .nav-link.disabled:hover,
.nav-sidebar .nav-link.disabled:focus {
  background-color: transparent;
  opacity: 0.5;
}
.nav-sidebar .nav-item-header {
  padding: 0.75rem 1.25rem;
}
.nav-sidebar .nav-item-header > i {
  display: none;
}
.nav-sidebar .nav-item-divider {
  margin: 0.5rem 0;
  height: 1px;
}

.nav-item-submenu > .nav-link {
  padding-right: 2.75rem;
}
.nav-item-submenu > .nav-link:after {
  content: '';
  font-family: 'icomoon';
  display: inline-block;
  font-size: 1rem;
  vertical-align: middle;
  line-height: 1;
  position: absolute;
  top: 0.75rem;
  margin-top: 0.1875rem;
  right: 1.25rem;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (prefers-reduced-motion: reduce) {
  .nav-item-submenu > .nav-link:after {
    transition: none;
  }
}
[dir='rtl'] .nav-item-submenu > .nav-link:after {
  content: '';
}

.nav-item-submenu.nav-item-open > .nav-link:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nav-group-sub {
  display: block;
}
.nav-item:not(.nav-item-expanded) > .nav-group-sub {
  display: none;
}
.nav-group-sub .nav-link {
  padding: 0.625rem 1.25rem 0.625rem 3.5rem;
}
.nav-group-sub .nav-group-sub .nav-link {
  padding-left: 4.75rem;
}
.nav-group-sub .nav-group-sub .nav-group-sub .nav-link {
  padding-left: 6rem;
}
.nav-group-sub .nav-item-submenu > .nav-link:after {
  top: 0.625rem;
}

.nav-scrollspy .nav-link.active + .nav,
.nav-scrollspy .nav-item-open .nav-link:not(.active) + .nav {
  display: block;
}

.nav-scrollspy .nav-link.active:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.nav-sidebar-icons-reverse .nav-link {
  padding-right: 3.5rem;
}
.nav-sidebar-icons-reverse .nav-link i {
  position: absolute;
  top: 0.75rem;
  right: 1.25rem;
  margin-right: 0;
}

.nav-sidebar-icons-reverse .nav-item-submenu .nav-link {
  padding-right: 4.5rem;
}
.nav-sidebar-icons-reverse .nav-item-submenu .nav-link:after {
  right: 3.25rem;
}

.nav-sidebar-icons-reverse .nav-group-sub .nav-link {
  padding-left: 2.5rem;
}
.nav-sidebar-icons-reverse .nav-group-sub .nav-link i {
  top: 0.625rem;
}

.nav-sidebar-icons-reverse .nav-group-sub .nav-group-sub .nav-link {
  padding-left: 3.75rem;
}

.nav-sidebar-icons-reverse .nav-group-sub .nav-group-sub .nav-group-sub .nav-link {
  padding-left: 5rem;
}

.nav-sidebar-bordered > .nav-item:not(.nav-item-divider) {
  margin-bottom: 0;
}

.sidebar-dark .nav-sidebar-bordered > .nav-item {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sidebar-dark .nav-sidebar-bordered > .nav-item-header {
  background-color: rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin: 0;
}

.sidebar-light .nav-sidebar-bordered > .nav-item {
  border-top: 1px solid #ddd;
}

.sidebar-light .nav-sidebar-bordered > .nav-item-header {
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  margin: 0;
}

.sidebar-dark {
  background-color: #212121;
  color: #fff;
}
.sidebar-dark .nav-sidebar .nav-link {
  color: rgba(255, 255, 255, 0.9);
}
.sidebar-dark .nav-sidebar .nav-link:not(.disabled):hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-dark .nav-sidebar .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.sidebar-dark .nav-sidebar .nav-item-open > .nav-link:not(.disabled),
.sidebar-dark .nav-sidebar > .nav-item-expanded > .nav-link {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.sidebar-dark .nav-sidebar > .nav-item > .nav-link.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}
.sidebar-dark .nav-sidebar .nav-item-header {
  color: rgba(255, 255, 255, 0.5);
}
.sidebar-dark .nav-sidebar .nav-item-divider {
  background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-dark .nav-sidebar > .nav-item-submenu > .nav-group-sub {
  background-color: rgba(0, 0, 0, 0.15);
}
.sidebar-dark .sidebar-section-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.sidebar-dark .btn-sidebar-expand {
  background-color: #212121;
  color: #fff;
}
.sidebar-dark .btn-sidebar-expand:hover,
.sidebar-dark .btn-sidebar-expand:focus {
  background-color: #2e2e2e;
}
.sidebar-dark .btn-sidebar-expand:not([disabled]):not(.disabled):active {
  background-color: #343434;
}

/* .sidebar-light {
  background-color: #fff;
  color: #333;
  background-clip: content-box;
}
.sidebar-light .nav-sidebar .nav-link {
  color: rgba(51, 51, 51, 0.85);
}
.sidebar-light .nav-sidebar .nav-link:not(.disabled):hover {
  color: #333;
  background-color: #eee;
}
.sidebar-light .nav-sidebar .nav-item > .nav-link.active {
  background-color: #ddd;
  color: #333;
}
.sidebar-light .nav-sidebar .nav-item-open > .nav-link:not(.disabled),
.sidebar-light .nav-sidebar > .nav-item-expanded > .nav-link {
  background-color: #eee;
  color: #333;
}
.sidebar-light .nav-sidebar > .nav-item > .nav-link.active {
  background-color: #ddd;
  color: #333;
}
.sidebar-light .nav-sidebar .nav-item-header {
  color: rgba(51, 51, 51, 0.5);
}
.sidebar-light .nav-sidebar .nav-item-divider {
  background-color: rgba(0, 0, 0, 0.125);
}
.sidebar-light .nav-sidebar > .nav-item-submenu > .nav-group-sub {
  background-color: transparent;
}
.sidebar-light .sidebar-section-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.sidebar-light .btn-sidebar-expand {
  background-color: #fff;
  color: #333;
}
.sidebar-light .btn-sidebar-expand:hover,
.sidebar-light .btn-sidebar-expand:focus {
  background-color: #f2f2f2;
}
.sidebar-light .btn-sidebar-expand:not([disabled]):not(.disabled):active {
  background-color: #ececec;
} */

.sidebar-component {
  display: none;
  position: static;
  width: 100%;
  margin-bottom: 1.25rem;
  border-radius: 0.25rem;
  /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
}

.sidebar-sections {
  background-color: transparent;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
}

@media (min-width: 576px) {
  .sidebar-expand-sm {
    position: static;
  }
  .sidebar-expand-sm.sidebar-collapsed {
    width: auto;
  }
  .sidebar-expand-sm.sidebar-collapsed .btn-sidebar-expand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
  .sidebar-expand-sm.sidebar-collapsed > *:not(.btn-sidebar-expand) {
    display: none !important;
  }
  .sidebar-expand-sm.sidebar-main {
    z-index: 99;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  }
  .sidebar-expand-sm.sidebar-main-resized {
    position: absolute;
    left: 0;
    transition: none;
  }
  .sidebar-expand-sm.sidebar-main-resized + * {
    margin-left: 3.5rem;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) {
    width: 3.5rem;
    overflow: hidden;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-title,
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .nav-group-sub,
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link > span,
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > div {
    display: none !important;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-section-body {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .navbar {
    padding: 0;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .navbar-brand {
    text-align: center;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .user-menu-vertical {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-hide {
    display: none;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-show {
    display: block;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header,
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link,
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    padding-left: 0;
    padding-right: 0;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header > * {
    margin: 0 !important;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i,
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    i {
    position: static;
    display: block;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link:after {
    content: none;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    display: -ms-flexbox;
    display: flex;
  }
  .sidebar-expand-sm.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > i {
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }
  .sidebar-expand-sm.sidebar-main-resized.sidebar-main-unfold {
    width: 17rem;
    z-index: 1022;
    /* box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35); */
  }
  .sidebar-expand-sm.sidebar-secondary {
    z-index: 98;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  }
  .sidebar-expand-sm.sidebar-right {
    z-index: 97;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  }
  .sidebar-expand-sm.sidebar-component {
    display: -ms-flexbox;
    display: flex;
    width: 17rem;
    z-index: 96;
  }
  .sidebar-expand-sm.sidebar-component-left {
    margin-right: 1.25rem;
  }
  .sidebar-expand-sm.sidebar-component-right {
    margin-left: 1.25rem;
  }
  .sidebar-expand-sm.sidebar-dark:not(.sidebar-component) + .sidebar-dark:not(.sidebar-component) {
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1) inset;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-expand-sm.sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 1.25rem;
      z-index: 1020;
    }
  }
}

@media (max-width: 575.98px) {
  .sidebar-expand-sm {
    z-index: 1038;
    transition: left ease-in-out 0.15s, right ease-in-out 0.15s;
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-expand-sm {
    transition: none;
  }
}

@media (min-width: 768px) {
  .sidebar-expand-md {
    position: static;
  }
  .sidebar-expand-md.sidebar-collapsed {
    width: auto;
  }
  .sidebar-expand-md.sidebar-collapsed .btn-sidebar-expand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
  .sidebar-expand-md.sidebar-collapsed > *:not(.btn-sidebar-expand) {
    display: none !important;
  }
  .sidebar-expand-md.sidebar-main {
    z-index: 99;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .sidebar-expand-md.sidebar-main-resized {
    position: absolute;
    left: 0;
    transition: none;
  }
  .sidebar-expand-md.sidebar-main-resized + * {
    margin-left: 3.5rem;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) {
    width: 3.5rem;
    overflow: hidden;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-title,
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .nav-group-sub,
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link > span,
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > div {
    display: none !important;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-section-body {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .navbar {
    padding: 0;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .navbar-brand {
    text-align: center;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .user-menu-vertical {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-hide {
    display: none;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-show {
    display: block;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header,
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link,
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    padding-left: 0;
    padding-right: 0;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header > * {
    margin: 0 !important;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i,
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    i {
    position: static;
    display: block;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link:after {
    content: none;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    display: -ms-flexbox;
    display: flex;
  }
  .sidebar-expand-md.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > i {
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }
  .sidebar-expand-md.sidebar-main-resized.sidebar-main-unfold {
    width: 17rem;
    z-index: 1022;
    box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35);
  }
  .sidebar-expand-md.sidebar-secondary {
    z-index: 98;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  }
  .sidebar-expand-md.sidebar-right {
    z-index: 97;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  }
  .sidebar-expand-md.sidebar-component {
    display: -ms-flexbox;
    display: flex;
    width: 17rem;
    z-index: 96;
  }
  .sidebar-expand-md.sidebar-component-left {
    margin-right: 1.25rem;
  }
  .sidebar-expand-md.sidebar-component-right {
    margin-left: 1.25rem;
  }
  .sidebar-expand-md.sidebar-dark:not(.sidebar-component) + .sidebar-dark:not(.sidebar-component) {
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1) inset;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-expand-md.sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 1.25rem;
      z-index: 1020;
    }
  }
}

@media (max-width: 767.98px) {
  .sidebar-expand-md {
    z-index: 1038;
    transition: left ease-in-out 0.15s, right ease-in-out 0.15s;
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-expand-md {
    transition: none;
  }
}

@media (min-width: 992px) {
  .sidebar-expand-lg {
    position: static;
  }
  .sidebar-expand-lg.sidebar-collapsed {
    width: auto;
  }
  .sidebar-expand-lg.sidebar-collapsed .btn-sidebar-expand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
  .sidebar-expand-lg.sidebar-collapsed > *:not(.btn-sidebar-expand) {
    display: none !important;
  }
  .sidebar-expand-lg.sidebar-main {
    z-index: 99;
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24); */
  }
  /* .sidebar-expand-lg.sidebar-main-resized {
    position: absolute;
    left: 0;
    transition: all 0.1s ease;
  } */
  .sidebar-expand-lg.sidebar-main-resized + * {
    margin-left: 3.5rem;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) {
    width: 3.5rem;
    overflow: hidden;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-title,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-group-sub,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link > span,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > div {
    display: none !important;
  }
  /* .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-section-body {
    padding-left: 0;
    padding-right: 0;
  } */
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .navbar {
    padding: 0;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .navbar-brand {
    text-align: center;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .user-menu-vertical {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-hide {
    display: none;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-show {
    display: block;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    padding-left: 0;
    padding-right: 0;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header > * {
    margin: 0 !important;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i,
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    i {
    position: static;
    display: block;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link:after {
    content: none;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    display: -ms-flexbox;
    display: flex;
  }
  .sidebar-expand-lg.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > i {
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }
  /* .sidebar-expand-lg.sidebar-main-resized.sidebar-main-unfold {
    width: 18.75rem;
    transition: all 0.1s ease;
    z-index: 1022;
    box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.2);
  } */
  .sidebar-expand-lg.sidebar-secondary {
    z-index: 98;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .sidebar-expand-lg.sidebar-right {
    z-index: 97;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .sidebar-expand-lg.sidebar-component {
    display: -ms-flexbox;
    display: flex;
    width: 17rem;
    z-index: 96;
  }
  .sidebar-expand-lg.sidebar-component-left {
    margin-right: 1.25rem;
  }
  .sidebar-expand-lg.sidebar-component-right {
    margin-left: 1.25rem;
  }
  .sidebar-expand-lg.sidebar-dark:not(.sidebar-component) + .sidebar-dark:not(.sidebar-component) {
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1) inset;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-expand-lg.sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 1.25rem;
      z-index: 1020;
    }
  }
}

@media (max-width: 991.98px) {
  .sidebar-expand-lg {
    z-index: 1038;
    transition: left ease-in-out 0.15s, right ease-in-out 0.15s;
  }
}
@media (max-width: 991.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-expand-lg {
    transition: none;
  }
}

@media (min-width: 1200px) {
  .sidebar-expand-xl {
    position: static;
  }
  .sidebar-expand-xl.sidebar-collapsed {
    width: auto;
  }
  .sidebar-expand-xl.sidebar-collapsed .btn-sidebar-expand {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
  }
  .sidebar-expand-xl.sidebar-collapsed > *:not(.btn-sidebar-expand) {
    display: none !important;
  }
  .sidebar-expand-xl.sidebar-main {
    z-index: 99;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .sidebar-expand-xl.sidebar-main-resized {
    position: absolute;
    left: 0;
    transition: none;
  }
  .sidebar-expand-xl.sidebar-main-resized + * {
    margin-left: 3.5rem;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) {
    width: 3.5rem;
    overflow: hidden;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-title,
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .nav-group-sub,
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link > span,
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > div {
    display: none !important;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-section-body {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .navbar {
    padding: 0;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .navbar-brand {
    text-align: center;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .user-menu-vertical {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-hide {
    display: none;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-show {
    display: block;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header,
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link,
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    padding-left: 0;
    padding-right: 0;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header > * {
    margin: 0 !important;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i,
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    i {
    position: static;
    display: block;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link:after {
    content: none;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i {
    margin-left: 0;
    margin-right: 0;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
    display: -ms-flexbox;
    display: flex;
  }
  .sidebar-expand-xl.sidebar-main-resized:not(.sidebar-main-unfold)
    .nav-sidebar
    .nav-item-header
    > i {
    margin-top: 0.1875rem;
    margin-bottom: 0.1875rem;
  }
  .sidebar-expand-xl.sidebar-main-resized.sidebar-main-unfold {
    width: 17rem;
    z-index: 1022;
    box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35);
  }
  .sidebar-expand-xl.sidebar-secondary {
    z-index: 98;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .sidebar-expand-xl.sidebar-right {
    z-index: 97;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  .sidebar-expand-xl.sidebar-component {
    display: -ms-flexbox;
    display: flex;
    width: 17rem;
    z-index: 96;
  }
  .sidebar-expand-xl.sidebar-component-left {
    margin-right: 1.25rem;
  }
  .sidebar-expand-xl.sidebar-component-right {
    margin-left: 1.25rem;
  }
  .sidebar-expand-xl.sidebar-dark:not(.sidebar-component) + .sidebar-dark:not(.sidebar-component) {
    box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1) inset;
  }
  @supports ((position: -webkit-sticky) or (position: sticky)) {
    .sidebar-expand-xl.sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
      top: 1.25rem;
      z-index: 1020;
    }
  }
}

@media (max-width: 1199.98px) {
  .sidebar-expand-xl {
    z-index: 1038;
    transition: left ease-in-out 0.15s, right ease-in-out 0.15s;
  }
}
@media (max-width: 1199.98px) and (prefers-reduced-motion: reduce) {
  .sidebar-expand-xl {
    transition: none;
  }
}

.sidebar-expand {
  position: static;
  z-index: 1038;
  transition: left ease-in-out 0.15s, right ease-in-out 0.15s;
}
.sidebar-expand.sidebar-collapsed {
  width: auto;
}
.sidebar-expand.sidebar-collapsed .btn-sidebar-expand {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}
.sidebar-expand.sidebar-collapsed > *:not(.btn-sidebar-expand) {
  display: none !important;
}
.sidebar-expand.sidebar-main {
  z-index: 99;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.sidebar-expand.sidebar-main-resized {
  position: absolute;
  left: 0;
  transition: none;
}
.sidebar-expand.sidebar-main-resized + * {
  margin-left: 3.5rem;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) {
  width: 3.5rem;
  overflow: hidden;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-title,
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-group-sub,
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link > span,
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header > div {
  display: none !important;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-section-body {
  padding-left: 0;
  padding-right: 0;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .navbar {
  padding: 0;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .navbar-brand {
  text-align: center;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .user-menu-vertical {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-hide {
  display: none;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-resize-show {
  display: block;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header,
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link,
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
  padding-left: 0;
  padding-right: 0;
  -ms-flex-pack: center;
  justify-content: center;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .sidebar-main-header > * {
  margin: 0 !important;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i,
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header i {
  position: static;
  display: block;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link:after {
  content: none;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-link i {
  margin-left: 0;
  margin-right: 0;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header {
  display: -ms-flexbox;
  display: flex;
}
.sidebar-expand.sidebar-main-resized:not(.sidebar-main-unfold) .nav-sidebar .nav-item-header > i {
  margin-top: 0.1875rem;
  margin-bottom: 0.1875rem;
}
.sidebar-expand.sidebar-main-resized.sidebar-main-unfold {
  width: 17rem;
  z-index: 1022;
  box-shadow: 0.25rem 0 1rem rgba(0, 0, 0, 0.35);
}
.sidebar-expand.sidebar-secondary {
  z-index: 98;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.sidebar-expand.sidebar-right {
  z-index: 97;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.sidebar-expand.sidebar-component {
  display: -ms-flexbox;
  display: flex;
  width: 17rem;
  z-index: 96;
}
.sidebar-expand.sidebar-component-left {
  margin-right: 1.25rem;
}
.sidebar-expand.sidebar-component-right {
  margin-left: 1.25rem;
}
.sidebar-expand.sidebar-dark:not(.sidebar-component) + .sidebar-dark:not(.sidebar-component) {
  box-shadow: 1px 0 0 rgba(255, 255, 255, 0.1) inset;
}
@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-expand.sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 1.25rem;
    z-index: 1020;
  }
}
@media (prefers-reduced-motion: reduce) {
  .sidebar-expand {
    transition: none;
  }
}

.sidebar-dark .nav-tabs .nav-item.show .nav-link:not(.active) {
  color: #fff;
}

.sidebar-light .nav-tabs:not(.nav-tabs-bottom) .nav-link.active {
  background-color: #fff;
}

.row-tile {
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.row-tile div[class*='col'] .btn {
  border-width: 0;
  border-radius: 0;
}
.row-tile div[class*='col'] .btn-light,
.row-tile div[class*='col'] .bg-white {
  border-color: rgba(0, 0, 0, 0.125);
}
.row-tile div[class*='col']:first-child .btn:first-child {
  border-top-left-radius: 0.25rem;
}
.row-tile div[class*='col']:first-child .btn:last-child {
  border-bottom-left-radius: 0.25rem;
}
.row-tile div[class*='col']:last-child .btn:first-child {
  border-top-right-radius: 0.25rem;
}
.row-tile div[class*='col']:last-child .btn:last-child {
  border-bottom-right-radius: 0.25rem;
}
.row-tile div[class*='col'] .btn + .btn {
  border-top-width: 1px;
}
.row-tile div[class*='col'] + div[class*='col'] .btn {
  border-left-width: 1px;
}

/* ------------------------------------------------------------------------------
 *
 *  # Boxed layout
 *
 *  Styles for main structure of content area in boxed layout
 *
 * ---------------------------------------------------------------------------- */
.layout-boxed-bg {
  background: url('../assets/images/backgrounds/boxed_bg.png') repeat;
}

.layout-boxed {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  box-shadow: -4px 2px 4px rgba(0, 0, 0, 0.15), 4px 2px 4px rgba(0, 0, 0, 0.15);
}
@media (min-width: 992px) {
  .layout-boxed,
  .layout-boxed > .navbar {
    width: 992px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1200px) {
  .layout-boxed,
  .layout-boxed > .navbar {
    width: 1200px;
  }
}
.layout-boxed .page-content {
  background-color: #eeeded;
}
