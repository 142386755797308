.login-form {
  max-width: 450px;
}

@media (min-width: 576px) {
  .login-form {
    min-width: 400px !important;
  }
}
@media (min-width: 576px) {
  .login-form {
    width: 20rem;
  }
}
.login-form {
  width: 100%;
}

.login-logo-container {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.recover-logo-container {
  border-radius: 50%;
  border: 3px solid #3f51b5;
}

.error-text {
  color: #CD3d64
}

::-ms-reveal {
  display: none;
}