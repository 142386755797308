/* Clases para títulos */
body {
  font-family: 'Inter', sans-serif;
}

:root {
  /* Font Size*/
  --font-size-3xs: 0.75rem; /* 12px */
  --font-size-2xs: 0.813rem; /* 13px */
  --font-size-xs: 0.875rem; /* 14px */
  --font-size-sm: 0.938rem; /* 15px */
  --font-size-base: 1rem; /* 16px */
  --font-size-lg: 1.125rem; /* 18px */
  --font-size-xl: 1.25rem; /* 20px */
  --font-size-2xl: 1.875rem; /* 30px */

  /* Custom Fonts*/
  --font-size-custom-title: 1.625rem; /* 26px */
  --font-size-custom-subtitle: 1.5rem; /*24px*/

  /* Line Height */
  --line-height-3xs: 1rem; /* 16px */
  --line-height-xs: 1.25rem; /* 20px */
  --line-height-sm: 1.5rem; /* 24px */
  --line-height-lg: 1.75rem; /* 28px */
  --line-height-2xl: 2.25rem; /* 36px */

  /* Font Weight */
  --font-weight-thin: 100;
  --font-weight-extralight: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-extrabold: 800;
  --font-weight-black: 900;
}

.custom-title {
  font-size: 1.75rem; /* 32px / 16px = 2rem */
}

/* Clases para subtítulos */
.ctm-subtitle {
  font-size: 1.25rem !important; /* 18px / 16px = 1.125rem */
}

.ctm-text-medium {
  font-size: 1rem; /* 16px / 16px = 1rem */
}
/* Clases para texto regular */
.ctm-text {
  font-size: 0.875rem; /* 16px / 16px = 1rem */
}

.ctm-text-small {
  font-size: 0.75rem !important;
}

.table-head-custom {
  color: #1A1B25 !important;
  font-size: 14.315px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.table-text, .table-text-400, .table-text-600,.table-text-700 {
  color: #687385 !important;
  font-size: 14.315px;
  font-style: normal;
  line-height: normal;
}

.table-text-400 {
  font-weight: 400;
}

.table-text-700 {
  font-weight: 700;
}
.table-text-600 {
  font-weight: 600;
}
