.checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 0.9rem;
}

.checkbox-container.disabled { 
  background: none;
}

.checkbox-container input {
  opacity: 0;
  cursor: pointer;
}

input[type="checkbox" i]:focus-visible {
  outline-offset: 0px !important;
  outline: 0px !important;
}

.checkmark {
  height: 18px;
  width: 18px;
  border: 1px solid #D0D5DD;
  border-radius: .25rem;
  position: relative;
}

.checkbox-container input:checked + .checkmark {
  background-color: var(--color-primary-blue);
  border: 1px solid var(--color-primary-blue) !important;
  border-radius: .25rem;
}

.checkbox-container.disabled .checkmark {
  opacity: 0.85;
} 

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-container input:checked + .checkmark:after {
  display: block;
}

.checkmark:after {
  left: 50%;
  top: 50%;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -60%) rotate(45deg);
}

.label {
  color: var(--color-subtitle);
}
