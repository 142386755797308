.risk-container {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
  background-color: #fff;
  height: 30px;
  max-width: 150px;
  font-weight: 500;
  cursor: pointer;
  gap: 5px;
}

.risk-text-low {
  color: #006908;
}
.risk-text-medium {
  color: #ffb46f;
}
.risk-text-high {
  color: #b3093c;
}

.risk-container:hover:not(.not-hover) {
  background-color: var(--hover);
}
