.tab-filter-container {
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.tab-filter {
  padding: 8px 16px;
  border: 1px solid #d0d5dd;
  color: #344054;
  font-weight: 600;
  cursor: pointer;
}

.tab-filter.first {
  border-right: none;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.tab-filter.last {
  border-left: none;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tab-filter:hover,
.tab-filter.active {
  background-color: #f9fafb;
}
