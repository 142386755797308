.disputeWarning {
  max-width: 1200px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.disputeWarningHeader {
  padding: 16px;
  border-bottom: 1px solid #eee;
}
.disputeWarningBody {
  padding: 16px;
  border-bottom: 1px solid #eee;
  background-color: #f9fafb;
}
.disputeWarningFooter {
  padding: 16px;
  height: 60px;
}
.disputeTextContainer {
  max-width: 760px;
}
.numberContainer {
  border-radius: 50%;
  border: 1px solid;
  max-width: 20px;
  width: 100%;
  height: 20px;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.itemList {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}

.noBorder {
  border: none !important;
}

.customerEmail, .customerEmail:hover {
  color: var(--color-primary)
}