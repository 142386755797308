.is-focus {
  background-color: var(--hover) !important;
}
.hover-icon {
  z-index: 15px;
}
.hover-icon:hover,
.hover-icon:focus {
  background-color: var(--hover-table) !important;
}
