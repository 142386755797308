.circular-progress {
  position: relative;
  height: 160px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.circular-progress::before {
  content: '';
  position: absolute;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background-color: #fff;
}

.circular-text {
  position: relative;
}