/* BADGES */

.badge {
  border: 2px solid;
  background-color: transparent;
  border-radius: 3rem;
  padding: 2px 7px;
  display: inline;
  font-size: 12px;
  font-weight: 600;
  vertical-align: middle;
}

.badge.green {
  border-color: #079455;
  color: #067647;
}
.badge.violet {
  border-color: #7A5AF8;
  color: #5925DC;
}
.badge.red {
  border-color: #f04438;
  color: #b42318;
}
.badge.grey {
  border-color: var(--border-color-gray);
  color: var(--color-gray);
}
.badge.orange {
  border-color: var(--border-color-orange);
  color: var(--color-orange);
}