.globaly {
  height: 100%;
  width: 100%;
  margin-bottom: 0 !important;
}
.errorDiv {
  margin-top: 80px;
  margin-bottom: 80px;
}
.errorTextContent {
  margin-top: 20px;
  width: 350px;
  color: #9f9f9f;
}
.errorTitle {
  font-size: 1.25rem;
}
.errorText {
  font-size: 0.875rem;
}
