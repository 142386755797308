.clock-text-yellow-light {
  color: #e29521;
  font-weight: 500;
}
.clock-text-yellow {
  color: #c84801;
  font-weight: 500;
}
.clock-text-blue {
  color: #1b6ea8;
  font-weight: 500;
}
.clock-text-blue-full {
  color: #1b6ea8;
  font-weight: 500;
}
