.filter-modal-content {
  width: 600px;
  background-color: #fff !important;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.rdp-day_selected:focus-visible {
  border: unset !important;
  outline: none;
  outline-offset: 0;
}

.rdrCalendarWrapper .rdrMonthAndYearWrapper {
  text-transform: capitalize;
}

.rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
  width: unset;
}

.rdrDateRangePickerWrapper .rdrMonth {
  width: 22em;
  text-transform: capitalize;
}

.rdrDateRangePickerWrapper .rdrDayNumber {
  font-weight: 500;
}
.rdrStartEdge,
.rdrEndEdge,
.rdrInRange,
.rdrDayNumber,
.rdrStaticRange.rdrStaticRangeSelected {
  color: var(--color-primary-blue) !important;
}
@media (max-width: 600px) {
  .pop-container-content {
    top: 120px !important;
    left: 2px !important;
  }
}
