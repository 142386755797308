.switch {
  position: relative;
  display: inline-block;
  width: 2.5em;
  height: 1.5em;
  margin-bottom: 0;
  vertical-align: middle;
  background-color: transparent;
  box-sizing: border-box; /* Ensures padding and border are included in width and height */
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-text {
  color: var(--color-text-sidebar);
  font-weight: 500;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f4f7;
  border: 2px solid #f2f4f7;
  transition: background-color 0.4s, border-color 0.4s;
  display: flex;
  align-items: center; /* Center vertically */
  justify-content: flex-start; /* Align left by default */
  padding: 0.15em; /* Adjust padding to center the circle */
  box-sizing: border-box; /* Ensures padding and border are included in width and height */
}

.slider:before {
  content: '';
  height: 1em;
  width: 1em;
  background-color: var(--color-background-white);
  transition: transform 0.4s, background-color 0.4s;
  border-radius: 50%;
  box-sizing: border-box; /* Ensures padding and border are included in width and height */
}

input:checked + .slider {
  background-color: var(--color-primary-blue);
  border: 0.1em solid var(--color-primary-blue);
}

input:checked + .slider:before {
  transform: translateX(1em);
  background-color: #fff;
}

/* input:checked + .slider-admin:before {
  transform: translateX(0.8em);
  background-color: var(--color-green);
} */

.slider.round {
  border-radius: 1em;
}

.slider.round:before {
  border-radius: 50%;
}
