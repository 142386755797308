.card-zoom {
  width: 100%;
  border-radius: 5px;
  position: relative;
  max-height: 300px;
}

.card-zoom-icon {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary-blue);
  width: fit-content;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
}
