.gridContainer {
  display: grid;
  grid-template-columns: repeat(1, auto);
  gap: 1rem;
  height: auto;
}

@media (min-width: 768px) {
  .gridContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .gridContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1400px) {
  .gridContainer {
    grid-template-columns: repeat(3, auto);
  }
}

.column1,
.column2,
.column3 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: start;
}
