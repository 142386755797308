.menuHeaderContainer {
  color: #344054;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem !important;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  justify-content: space-between;
  cursor: pointer;
}
.menuItemContainer {
  color: #344054;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.3rem 1.625rem !important;
  margin-left: 0;
  transition: background-color ease-in-out 0.15s, color ease-in-out 0.15s;
  cursor: pointer;
  border-left: 6px solid transparent;
  font-size: var(--font-size-sm);
  line-height: var(--line-height-xs);
  font-weight: var(--font-weight-semibold);
}
.menuSelected.menuItemContainer::before {
  content: ''; /* Importante tener este contenido */
  position: absolute;
  top: 0;
  left: 10px; /* Ajusta según tu necesidad para que el borde esté adelantado */
  height: 100%;
  width: 6px; /* Ancho del borde */
  background-color: var(--color-primary-blue); /* Color del borde */
}

.menuSelected {
  color: var(--color-primary-blue) !important
}

.menuItemContainer:hover {
  background-color: var(--hover) !important;
}

.disabledItem {
  color: #ccc !important;
}

.disabledItem:hover,
.noHover:hover {
  background-color: transparent !important;
  pointer-events: none;
  cursor: default;
}

.notification {
  display: inline-block;
  padding: 5px;
  background-color: #faf7f7;
  border: 1px solid var(--color-border-table);
  color: var(--color-text-sidebar);
  border-radius: 20px;
  font-size: 11px;
  text-align: center;
  min-width: 22px;
  min-height: 22px;
  line-height: 10px;
  margin-left: 0.5rem;
}