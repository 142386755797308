.field {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  position: relative;
}

.field input,
.field select {
  display: block;
  width: 100%;
  height: calc(1.5715em + 0.875rem + 2px);
  padding: 0.4375rem 0.875rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5715;
  color: var(--color-text);
  background-color: var(--color-background-btn);
  background-clip: padding-box;
  border: 1px solid var(--color-border-table);
  border-radius: 0.25rem;
}
.field input:focus {
  color: var(--color-text);
  border-color: #45748a;
  outline: 0;
}
#icon-wellet {
  position: absolute;
  left: 10px;
  color: var(--color-text);
  top: 10px;
}

.field:not(.input-search) .input-icon {
  padding-left: 41px;
  border-radius: 0.375rem !important;
}

.field .floating-placeholder.input-icon {
  left: 0;
}

.field.focus .floating-placeholder,
.field .PhoneInput--focus ~ .floating-placeholder,
.field input:focus ~ .floating-placeholder,
.field input:not(:focus) .floating-placeholder,
.field input:not([value='']) ~ .floating-placeholder {
  font-size: 11px;
  opacity: 1;
  top: 2px;
  -webkit-transform: none;
  transform: none;
}

.field .floating-placeholder {
  color: #000;
  left: 15px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.2s ease;
}

.field.error {
  margin-bottom: 3rem !important;
}

.field.error input {
  border: 2px solid #f80005;
}

.field.error .floating-placeholder {
  color: #e00005;
}

.error-label {
  background-color: #f80005;
  color: #fff;
  margin-top: 8px;
  font-size: 12px;
  padding: 2px 5px 2px 8px;
  position: absolute;
  width: 100%;
}

.error-label::after {
  content: '';
  position: absolute;
  border-width: 5px;
  border-style: solid;
  margin-left: -5px;
  bottom: 100%;
  left: 15px;
  border-color: transparent transparent #f80005 transparent;
}

.error-label svg {
  margin-top: -2px;
  display: inline;
}

.reveal {
  position: absolute;
  color: rgb(49, 0, 128);
  right: 40px;
  top: 50%;
  text-indent: 20px;
  transform: translate(50%, -50%);
}
.rdp-dropdown_year,
.rdp-dropdown_month {
  color: var(--color-subtitle) !important;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  color: var(--color-subtitle) !important;
  background-color: var(--color-background-app) !important;
  border: 1px solid var(--color-border-table) !important;
  border-radius: 6px;
}
.rdp-dropdown {
  background-color: var(--color-background-sidebar) !important;
  color: var(--color-text) !important;
  padding: 10px !important;
}
.rdp-nav_button_previous,
.rdp-nav_button_next {
  color: var(--color-subtitle) !important;
}
.rdp-nav_button_previous:hover,
.rdp-nav_button_next:hover {
  background-color: var(--hover) !important;
}
.rdp-day:hover,
.rdp-day_selected {
  background-color: var(--color-primary-blue) !important;
  color: #fff !important;
}
.rdp-table {
  color: var(--color-text) !important;
}
@media (max-width: 768px) {
  .rdp-day:hover,
  .rdp-day_selected {
    background-color: transparent;
  }
  .rdp-nav_button_previous:hover,
  .rdp-nav_button_next:hover {
    background-color: transparent;
  }
}

.input-search input {
  box-shadow: 0px 1px 2px 0px #1018280D;
  padding-left: 41px;
  border-radius: 8px !important;
}
.input-search input::placeholder {
  color: #667085;
}