.contentActionButton {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}
.contentActionButton span {
  color: #ffa757;
  font-size: 12px;
  margin-right: 5px;
}

.cancelBadge {
  background-color: var(--hover);
}
.actionButton {
  height: 30px;
  width: 30px;
  filter: grayscale(100%);
  border: 1px solid var(--color-border-table);
  background-color: var(--color-background-app);
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actionButton i {
  transition: transform 0.3s ease-in-out;
}
.actionButton:hover i {
  transform: scale(1.1);
}
.submenu-action-btn {
  background-color: var(--color-background-app);
  z-index: 100;
  width: 140px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  list-style-type: none;
  margin: 5px 0 0 0;
  padding: 0;
}
.submenu-action-item {
  text-align: center;
  cursor: pointer;
  padding: 5px;
}
.submenu-action-item:hover {
  background-color: var(--hover);
}
.modal-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}
