.iconContainer {
  position: relative;
  display: inline-block;
  color: #667085;
}

.badgeNotification {
  position: absolute;
  top: 0;
  right: -4px;
  background-color: var(--color-primary-blue);
  color: white;
  border-radius: 50%;
  font-size: 12px;
  height: 7px;
  width: 7px;
}
