.btn-outline-light {
  color: #667085 ;
}

tr .btn-outline-light:hover {
  background-color: var(--hover-table) !important;
}
@media (max-width: 768px) {
  .btn-outline-light:hover {
    background-color: transparent !important;
  }
}
.icon-button-active,
.btn-outline-light:hover {
  background-color: #f2f4f7 !important;
  border-radius: 6px !important;
  color: #344054 !important;
}
