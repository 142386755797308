.card-campaign,
.small-card-campaign {
  border: 1px solid #eaecf0;
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  width: auto;
}
.small-card-campaign {
  max-height: 184px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-campaign-padding {
  padding: 1rem 14px 1rem 18px;
}

.campaign-title {
  margin-top: 10px;
  font-size: 18px;
}

.campaign-icon {
  background-color: #1d9bf0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
