.badge {
  border-radius: 4px;
  line-height: normal;
  padding: 1px 6px;
  border: 1px solid;
  height: 30px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  width: 100%;
}
.badge i {
  font-size: 12px !important;
  margin-bottom: -4px !important;
}

.badge .icon-checkmark3 {
  font-size: 0.75rem;
}
/* BADGES */
/* .badge-red {
  color: var(--color-red);
  background-color: var(--bg-red);
}
.badge-red-strong {
  color: #ffffff;
  background-color: #b3093c;
  border-color: #b3093c;
}
.badge-green {
  color: var(--color-green);
  background-color: var(--bg-green);
}
.badge-blue {
  color: var(--color-blue);
  background-color: var(--bg-blue);
}
.badge-orange {
  color: var(--color-orange);
  background-color: var(--bg-orange);
}
.badge-gray {
  color: var(--color-gray);
  background-color: var(--bg-gray);
} */
/* BADGES NIVEL DE AFILIADO */
.badge-diamond {
  border-color: #242424;
  background-color: #545454;
  color: #fff;
}

.badge-presidente {
  border-color: #676767;
  background-color: #8e8e8e;
  color: #fff;
}

.badge-platinum-pro {
  border-color: #747272;
  background-color: #747272;
  color: #fff;
}

.badge-platinum {
  border-color: #6b6b6b;
  background-color: #dbdbdb;
  color: #000;
}
.badge-gold {
  border-color: #996e25;
  background-color: #ffd261;
  color: #000;
}
.badge-silver {
  border-color: #6997b7;
  background-color: #aee4ff;
  color: #000;
}
.badge-bronze {
  border-color: #96602b;
  background-color: #cd7f32;
  color: #000;
}
.badge-default {
  border: 0;
  border-radius: 5px;

  background-color: var(--color-background-btn);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  z-index: 100;
  color: var(--color-text);
}

/* BADGES */

.badge-wellet {
  border: 1px solid #d0d5dd;
  background-color: #f9fafb;
  border-radius: 3rem;
  padding: 2px 7px;
  display: inline;
  font-size: 12px;
  font-weight: 500;
  vertical-align: middle;
}
.badge-wellet.strong {
  border: 1.5px solid !important;
}
.badge-wellet.green {
  border-color: #17b26a;
  color: #067647;
  background-color: #ecfdf3;
}
.badge-wellet.violet {
  border-color: #d6bbfb;
  color: #53389e;
  background-color: #f9f5ff;
}
.badge-wellet.red {
  border-color: #f04438;
  color: #b42318;
  background-color: #fef3f2;
}
.badge-wellet.grey {
  border-color: var(--border-color-gray);
  color: var(--color-gray);
  background-color: var(--bg-gray);
}
.badge-wellet.orange {
  border-color: var(--border-color-orange);
  color: var(--color-orange);
  background-color: var(--bg-orange);
}

.badge-wellet.strong {
  border: 1.5px solid !important;
}
.badge-wellet-green-icon {
  color: #17b26a;
}
.badge-wellet.blue {
  border-color: #b9e6fe;
  color: #1570ef;
  background-color: #f0f9ff;
}

.badge-wellet-red-icon {
  color: #b2172c;
}

.badge-wellet.primary {
  border-color: #c0fbbb;
  color: #53389e;
  background-color: #dfffcb;
}
.badge-wellet.primary svg {
  color: #17b26a;
}
