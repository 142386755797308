.bannerContainer {
    background-color: var(--color-background-banner);
    border: 1px solid var(--color-border-banner);
    color: var(--color-text-white);
    border-radius: 12px;
    width: 100%;
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 3px 6px rgba(194, 194, 194, 0.23), 0 3px 6px rgba(194, 194, 194, 0.23);
    animation: fadeIn 0.6s ease-in-out;
    margin-bottom: 30px;
}

.iconTextContainer {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 80%;
}

.iconContainer {
    background-color: var(--color-primary-new);
    border-radius: 12px;
    padding: 10px;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textContainer {
    gap: 4px;
    font-size: 14px;
    font-weight: 400;
}

.actionsContainer {
    display: flex;
    padding-left: 10px;
}

.iconClose {
    margin-left: 5px;
}

.spanText {
    font-weight: 600;
    color: var(--color-text-white);
    cursor: pointer;
    font-size: 16px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}