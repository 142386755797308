.cardEvidence {
    padding: 16px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-top: 1rem;
    max-width: 700px;
}
.clientContainer {
    width: 100%;
    max-width: 400px;
}