.btnOutline {
  border-radius: 50px;
  padding: 0.2rem 1rem;
  color: var(--color-btn-primary-text);
  border: 1px solid;
  border-color: var(--color-btn-primary-text);
  font-weight: 700;
  height: 1.8rem;
  width: auto;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  cursor: pointer;
  background-color: var(--color-background-app);
  transition: background-color 0.2s ease-in-out;
  position: relative;
}

.btnOutline:hover {
  background-color: var(--hover);
}
