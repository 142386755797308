.online {
  border-radius: 8px;
  background-color: #f9f5ff;
  color: #53389e;
  font-size: 14px;
  height: 25px;
  width: 100%;
}
.dot {
  width: 8px;
  height: 8px;
  border-radius: 9999px;
}

.dot-green {
  background-color: #17b26a;
}
.dot-yellow {
  background-color: #ffb703;
}
.dot-red {
  background-color: #d92d20;
}
.dot-violet {
  background-color: #6938ef;
}
.dot-grey {
  background-color: var(--border-color-gray);
}
.dot-orange {
  background-color: var(--bg-orange);
}

.dot.green {
  background-color: #079455;
}

.dot.red {
  background-color: #d92d20;
}
.dot.violet {
  background-color: #7A5AF8;
}
.dot.grey {
  background-color: var(--border-color-gray);
}
.dot.orange {
  background-color: var(--bg-orange);
}
