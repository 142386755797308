.input-style,
.PhoneInput .PhoneInputInput {
  border: none;
  border-radius: 0.5rem;
  min-height: 34px;
  background-color: var(--color-background-btn);
  width: 100%;
  outline: 0;
  color: var(--color-primary);
  padding: 10px 14px 10px 14px;
  font-weight: 500;
}

.input-base-container:not(.input-base-container input[type='checkbox']),
.PhoneInput .PhoneInputInput {
  border: 1px solid #d0d5dd;
}

.input-base-container:not(.input-base-container input[type='checkbox']),
.PhoneInput .PhoneInputInput {
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.input-base-container input[type='checkbox'] {
  min-height: auto;
}

.disabled .PhoneInputInput {
  background: var(--color-border-table);
}

.input-style.with-icon:focus,
.PhoneInputInput:focus {
  border-left: none;
}
.input-base-container.input-error {
  border: 1px solid var(--color-red) !important;
}
.input-error .PhoneInputInput {
  border: 1px solid var(--color-red);
}
.input-icon {
  background-color: #fafafa;
  color: var(--color-text);
  margin-right: -1px;
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-icon + .input-style,
.file-btn button {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.input-base-container:focus-within:not(.input-error) {
  border: 1px solid var(--color-primary-blue) !important;
}
.input-base-container:has(input[type='checkbox']):not(.input-base-container.select-container),
.input-base-container:focus-within:has(input[type='checkbox']):not(
    .input-base-container.select-container
  ) {
  border: none !important;
}

.input-style.with-icon {
  border-left: none;
}

.file-btn > .remove-file {
  color: var(--color-subtitle);
  background-color: transparent;
  border-top-right-radius: 0rem;
  border-bottom-right-radius: 0rem;
  border-right: 1px solid #d0d5dd;
  border-left: none;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--color-text);
  -webkit-box-shadow: 0 0 0px 40rem var(--color-background-btn) inset;
}
.input-end {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.preview-image {
  max-height: 300px;
  height: 100%;
  width: 100%;
  border-style: none;
  object-fit: contain;
}
.file-dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: var(--color-background-card);
  border: 1px solid #d0d5dd;
  border-radius: 0.5rem;
  font-size: 1.25rem;
  text-align: center;
  vertical-align: middle;
  color: var(--color-text);
}

.textarea-style {
  padding: 0.25rem 0.575rem;
}
.send-file {
  border-left: none !important;
  color: var(--color-subtitle);
  background-color: transparent;
}
.send-file:hover {
  background-color: transparent;
}
/* 
[data-theme='dark'] {
  .input-file-container button {
    border: 1px solid var(--color-border-table);
    border-left: none;
  }
} */
.react-time-picker__wrapper {
  border: none !important;
}
.react-time-picker__inputGroup__input {
  outline: 0;
}
.react-time-picker__inputGroup__input:invalid {
  background: none !important;
}

.disabled.PhoneInput {
  background: none;
}
.input-disabled {
  background-color: var(--color-border-table) !important;
}

.input-icon.end {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.rdp-dropdown_year,
.rdp-dropdown_month {
  color: var(--color-subtitle) !important;
}

.rdp-dropdown:focus-visible:not([disabled]) + .rdp-caption_label {
  color: var(--color-subtitle) !important;
  background-color: var(--color-background-app) !important;
  border: 1px solid var(--color-border-table) !important;
  border-radius: 6px;
}
.rdp-dropdown {
  background-color: var(--color-background-sidebar) !important;
  color: var(--color-text) !important;
  padding: 10px !important;
}
.rdp-nav_button_previous,
.rdp-nav_button_next {
  color: var(--color-subtitle) !important;
}
.rdp-nav_button_previous:hover,
.rdp-nav_button_next:hover {
  background-color: var(--hover) !important;
}
.rdp-day:hover,
.rdp-day_selected {
  background-color: var(--color-primary-blue) !important;
  color: #fff !important;
}
.rdp-table {
  color: var(--color-text) !important;
}
@media (max-width: 768px) {
  .rdp-day:hover,
  .rdp-day_selected {
    background-color: transparent;
  }
  .rdp-nav_button_previous:hover,
  .rdp-nav_button_next:hover {
    background-color: transparent;
  }
}

.arrow-input-description {
  margin-top: 2px;
}

.hideArrow input::-webkit-outer-spin-button,
.hideArrow input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.hideArrow input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.multiple-file {
  border: 1px solid #d0d5dd;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
}

.logo-image {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  background-color: transparent;
}

.logo-image:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.logo-image img {
  width: 100%;
}

.logo-image-icon {
  position: absolute;
  opacity: 0;
}

.logo-image:hover .logo-image-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.logo-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.input-file-drag-drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 2px solid #675dff;
  border-radius: 0.75rem;
  text-align: center;
  vertical-align: middle;
  color: var(--color-text);
  padding: 1.5rem;
  width: 100%;
}

.requiredLabel {
  margin-left: 4px;
  color: var(--color-primary-new);
  font-weight: 900;
}