.cardCheck {
  border: 1px solid #eaecf0;
  border-radius: 12px;
  padding: 1rem 1rem 1rem 1rem;
  cursor: pointer;
  position: relative;
}

.titleCardCheck {
  color: #344054;
  font-weight: 500;
}

.deleteIcon {
  margin-right: 10px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.iconCheckbox {
  margin-left: 10px;
}
