.comments-container {
  width: 100%; /* Alinea el globo de conversación a la izquierda */
}

.comments {
  background-color: #f9fafb;
  color: #101828;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid #eaecf0;
  padding: 10px 14px;
  position: relative;
  max-width: 100%;
  width: auto; /* Ancho máximo del globo de conversación */
}

.comments p {
  margin: 0;
}
