.single_toggle_card {
  border: 1px solid var(--color-primary-blue);
  -webkit-transition: all 0.3s linear;
  -moz-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  -ms-transition: all 0.3s linear;
  -khtml-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.toggle_card_icon {
  max-width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggle_card_area {
  position: relative;
  margin: 0 10px;
}

.toggle_checkbox {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  cursor: pointer;
}

.toggle_checkbox ~ .single_toggle_card {
  border: 1px solid var(--color-border-table);
  border-radius: 100px;
  min-width: 180px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
  background-color: var(--color-background-sidebar);
  padding: 5px 15px;
}

.toggle_checkbox:hover ~ .single_toggle_card {
  border: 1px solid var(--color-primary-blue);
  border-radius: 100px;

  background-color: var(--color-background-sidebar);
}

.toggle_checkbox:checked ~ .single_toggle_card {
  border: 1px solid var(--color-primary-blue);
  border-radius: 100px;
  background-color: var(--color-primary-blue);
}
.toggle_card_icon h6 {
  margin: 0;
  color: var(--color-text-sidebar);
  transition: color 0.3s linear;
}
/*Icon Selector*/
.toggle_checkbox:checked ~ .single_toggle_card .toggle_card_icon h6 {
  color: #fff;
}

.status-container {
  cursor: pointer;
  font-weight: 500;
  color: var(--color-text-disabled-darker);
  background-color: var(--color-background-app);
  border-radius: 0.375rem;
}
.status-active {
  border: 1px solid var(--color-border-table);
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  padding: 5px 24px;
}
.status-inactive {
  border: 1px solid var(--color-border-table);
  padding: 5px 24px;
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}
.status-active.inactive {
  border-right: none;
}
.status-inactive.inactive {
  border-left: none;
}
.status-active.active {
  border-color: var(--border-color-green);
  background-color: var(--bg-green);
  color: var(--color-text-approved);
}

.status-inactive.active {
  border-color: #bac9b4;
   background-color: var(--bg-gray);
  color: var(--color-text-pending);
}
