.modal {
  color: var(--color-text);
}
.modal-content {
  position: relative;
  border-radius: 12px !important;
  background-color: var(--color-background-sidebar) !important;
  box-shadow: 0px 8px 8px -4px #10182808;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  display: none;
}

.modal-header {
  padding: 15px !important;
}
.modal-header h5 {
  font-size: 1rem;
  font-weight: bold;
}

.modal-header,
.modal-background {
  border-bottom: 1px solid var(--color-border-table) !important;
  background-color: var(--color-background-sidebar);
  color: var(--color-general);
}
.modal-body {
  padding: 20px !important;
  overflow-x: hidden !important;
}
.modal-custom-body {
  color: #475467;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow-x: hidden !important;
}
.modal-background {
  background-color: var(--color-background-sidebar);
  padding: 0.75rem 1.25rem;
  color: var(--color-text);
}

.modal-custom-close {
  position: absolute;
  top: 12px;
  right: 12px;
}

.modal-title {
  font-weight: 600;
  font-size: 18px;
  color: #101828;
  line-height: 28px; /* 155.556% */
}

.modal-footer {
  padding: 1rem 1.25rem !important;
  background-color: var(--color-background-sidebar);
  color: var(--color-text);
}

.custom-popup-overlay {
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.7);
}
.custom-popup-content {
  width: 100%;
  margin: 0 auto !important;
}

@media (min-width: 576px) {
  .modal-dialog {
    width: fit-content;
    margin: 1.75rem auto;
  }
}
