.buttonGallery {
  outline: none;
  border: 1px var(--color-border-table) solid;
  border-radius: 0.8rem;
  cursor: pointer;
  font-size: 14px;
  padding: 16px 24px;
  color: var(--color-secondary);
  background-color: #fff;
}
.buttonGallery:hover {
  opacity: 0.7;
}
.error {
  border: 1px var(--color-red) dashed;
}
.disabled {
  background-color: #eaecf0;
}
.buttonGallery.disabled:hover {
  opacity: 1;
  cursor: not-allowed; /* Optionally change the cursor to indicate it's disabled */
}
@media (max-width: 768px) {
  .buttonGallery:hover {
    opacity: 1;
  }
}
