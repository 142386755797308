.filter-card {
  border: 1px solid var(--color-border-table);
  border-radius: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
  width: auto;
  padding: 0.875rem;
  min-width: 150px;
  max-width: 200px !important;
}
.reservations.filter-card {
  max-width: 120px !important;
}
.filter-card:hover {
  border: 1px solid var(--color-border-primary) !important;
}
.filter-card-selected {
  border: 1px solid var(--color-primary-blue) !important;
  transition: all 0.3s ease;
  /* box-shadow: 0px 0px 0px 1px var(--color-primary) !important; */
}