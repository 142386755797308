.badgeNotification {
  background-color: var(--color-primary-blue);
  border-radius: 50%;
  height: 8px;
  width: 8px;
  margin-top: 8px;
  margin-right: 5px;
}

.notificationMenuItem {
  white-space: normal;
  display: block;
  width: 100%;
  padding: 0.5rem 1.25rem;
}
.notificationMenuItem:hover {
  cursor: pointer;
  background-color: var(--hover) !important;
  color: var(--color-text);
  text-decoration: none;
}
.dividerLine {
  height: 1px;
  width: 100%;
  background-color: #eaecf0;
}
.paginationNotification {
  padding: 0 1.25rem 10px 1.25rem;
}
.dividerContainer {
  padding: 0 1.25rem 10px 1.25rem;
}
.menuItemContainer {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 300px;
}
