.wrapper {
  width: 400px;
  margin-left: 100px;
  margin-bottom: 50px;
}

.containerHeader {
  display: flex;
  justify-content: center;
  border-bottom: 1px solid var(--color-border-table);
  padding-bottom: 15px;
}

.content {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 40px;
}

.points {
  flex: 1;
  text-align: right;
}

.status {
  text-align: left;
}

.statusLoyalty {
  font-size:  0.875rem;
  font-weight: 600;
  color: var(--color-primary-text);
  border: 1px solid var(--color-border-primary);
  border-radius: 20px;
  padding: 4px 10px;
  text-align: center;
}

/* Timeline Styles */
.timeline {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 20px 0 0 0px;
  position: relative;
}

.timeline::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 24.3%;
  width: 0;
  border-left: 1px dashed #ddd;
  z-index: 0;
}

.timelineRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  position: relative;
  padding-left: 0;
  z-index: 1;
}

.pointInfo {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 65px;
  margin-right: 10px;
}

.pointsValue {
  text-align: right;
  font-weight: normal;
}

.card {
  flex: 1;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  z-index: 1;
}

.cardContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333;
  width: 100%;
}

.card:hover {
  background-color: #721bff1c;
}

.radioInput {
  margin: 0;
  padding: 0;
  align-self: center;
  position: relative;
  z-index: 1;
}

.radioInput:checked {
  accent-color: var(--color-primary-text);
  color: var(--color-primary-text);
  background-color: var(--color-primary-text);
}

.cardSelected {
  border: 1px solid var(--color-border-primary);
}

.cardSelected .cardContent {
  font-weight: 600;
}

.currentBadge {
  display: inline-block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #fff;
  border: 1px solid var(--color-border-primary);
  border-radius: 20px;
  padding: 0px 10px;
  text-align: center;
  background-color: var(--color-primary-text);
}

@media (max-width: 480px) {
  .wrapper {
    width: 100%;
    margin: 30px auto 50px;
    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }

  .pointsValue {

    text-align: right;
    font-weight: normal;
  }

  .cardContent {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #333;
    width: 100%;
  }

  .timeline::before {
    left: 26.8%;
  }

  .pointInfo {
    flex: 0 0 20%;
  }

  .card {
    flex: 1;
    width: auto;
  }

  .timelineRow {
    gap: 0.5rem;
  }

  .radioInput {
    align-self: flex-start;
  }
}

@media (max-width: 375px) {
  .timeline::before {
    left: 28%;
  }
}
