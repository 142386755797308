.btn-custom-text-editor {
  background-color: var(--color-background-btn);
  color: var(--color-text);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
}
.toolbar-class {
  background-color: var(--color-background-sidebar);
  border: 1px solid var(--color-border-table);
  border-radius: 12px;
}
.toolbar-new-class {
  background-color: none;
  border: none
}
.toolbar-class a {
  color: var(--color-text);
}
.editor-class {
  background-color: var(--color-background-sidebar);
  border: 1px solid var(--color-border-table);
  border-radius: 12px;
  padding: 5px;
  color: var(--color-text);
}

.editor-new-class {
  border: 1px solid #D0D5DD;
  min-height: 140px;
  border-radius: 12px;
  padding: 12px;
}

.editor-new-class .public-DraftEditorPlaceholder-root,  .editor-new-class .public-DraftEditorPlaceholder-hasFocus {
  color: #667085;
}

.editor-new-class .public-DraftStyleDefault-block {
  margin: 0;
}

.editor-class.error-text-editor {
  border: 1px solid var(--color-border-error);
}
.wrapper-class {
  border: 1px solid var(--color-border-table);
  border-radius: 12px;
  padding: 5px;
}
.rdw-image-modal,
.rdw-colorpicker-modal {
  z-index: 200;
}
.rdw-dropdown-wrapp {
  border: 1px solid var (--color-border-table) !important;
  border-radius: 12px !important;
}
.noindex {
  z-index: unset !important;
}
.btn-editor {
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  border-radius: 5px;
  opacity: 0.7;
  /* box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(64, 68, 82, 0.08) 0px 2px 5px 0px; */
  min-height: 28px;
  min-width: 28px;
  transition: background-color 0.3s ease, margin 0.3s ease;
}
.btn-editor:hover {
  box-shadow: none;
  background-color: var(--hover);
}

.rdw-dropdown-wrapper:hover {
  box-shadow: none;
}
.rdw-dropdown-wrapper:hover a {
  color: var(--color-text) !important;
}

.rdw-option-active {
  opacity: 1;
  box-shadow: none;
}

.rdw-dropdown-optionwrapper,
.rdw-dropdown-optionwrapper:hover {
  background-color: var(--color-background-sidebar);
  box-shadow: none;
  width: fit-content;
  min-width: 50px;
}
.rdw-dropdownoption-default {
  background-color: var(--color-background-sidebar);
}
.rdw-dropdownoption-default:hover {
  background-color: var(--hover);
}
.rdw-dropdown-carettoopen {
  border-top: 6px solid var(--color-text);
}
.image-btn-editor {
  background-image: var(--image-btn-editor);
}
.color-btn-editor {
  background-image: var(--color-btn-editor);
}
.bold-btn-editor {
  background-image: var(--bold-btn-editor);
}
.italic-btn-editor {
  background-image: var(--italic-btn-editor);
}
.underline-btn-editor {
  background-image: var(--underline-btn-editor);
}
.striketrhough-btn-editor {
  background-image: var(--striketrhough-btn-editor);
}
.monospace-btn-editor {
  background-image: var(--monospace-btn-editor);
}
.superscript-btn-editor {
  background-image: var(--superscript-btn-editor);
}
.subscript-btn-editor {
  background-image: var(--subscript-btn-editor);
}
.unordered-btn-editor {
  background-image: var(--unordered-btn-editor);
}
.ordered-btn-editor {
  background-image: var(--ordered-btn-editor);
}
.indent-btn-editor {
  background-image: var(--indent-btn-editor);
}
.outdent-btn-editor {
  background-image: var(--outdent-btn-editor);
}
.left-btn-editor {
  background-image: var(--left-btn-editor);
}
.center-btn-editor {
  background-image: var(--center-btn-editor);
}
.right-btn-editor {
  background-image: var(--right-btn-editor);
}
.justify-btn-editor {
  background-image: var(--justify-btn-editor);
}
.link-btn-editor {
  background-image: var(--link-btn-editor);
}
.unlink-btn-editor {
  background-image: var(--unlink-btn-editor);
}
.embedded-btn-editor {
  background-image: var(--embedded-btn-editor);
}
.emoji-btn-editor {
  background-image: var(--emoji-btn-editor);
}
.remove-btn-editor {
  background-image: var(--remove-btn-editor);
}
.undo-btn-editor {
  background-image: var(--undo-btn-editor);
}
.redo-btn-editor {
  background-image: var(--redo-btn-editor);
}
