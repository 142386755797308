.status-container {
  display: flex;
  padding: 2px 6px;
  align-items: center;
  gap: 4px;
  border-radius: 6px;
  border: 1px solid #d0d5dd;
  background: #fff;
  max-width: fit-content;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  max-height: 30px;
}
