/* ------------------------------ General / Global Styles  ------------------------------*/

/* ---------- Main Styles ----------*/
body {
  color: var(--color-primary);
}
ul {
  margin: 0;
}
a {
  color: var(--color-primary-blue);
}

.App {
  display: flex;
  justify-content: flex-start;
  background-color: var(--color-background-sidebar);
}
.page {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: var(--color-background-app);
  position: relative;
  height: 100vh;
}
.max-height {
  max-height: 400px;
  overflow-y: auto;
}
.left-center {
  left: 17px;
}

.sentences-capitalize:first-letter {
  text-transform: capitalize;
}
.custom-link {
  color: var(--color-custom-link);
}
.custom-link:hover {
  color: var(--color-text-black);
}
.custom-link-file {
  cursor: pointer;
  color: var(--color-custom-link);
  text-decoration: underline;
}
.custom-link-file:hover {
  color: var(--color-text-black);
}
.custom-link-file-dispute {
  cursor: pointer;
  color: #675dff;
}
.custom-link-file-dispute:hover {
  color: #584fdb;
  text-decoration: underline;
}
.card {
  border-radius: 0.75rem;
  box-shadow: none;
}
.card .currencyCode {
  color: #fff;
  opacity: 0.75;
}

.scrollable-content-children {
  position: relative;
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
  max-height: calc(100vh - 225px);
}

.dropdown-container .nav-item {
  padding-left: 2.5rem !important;
}
/* ---------- Buttons Styles----------*/

.btn-general {
  border: none;
  border-radius: 0.25rem;
  padding: 0.3125rem 1rem;
  min-width: 80px;
  transition: all 0.3s ease-in-out;
  z-index: 79;
}
.btn-custom-primary {
  background-color: var(--color-primary-blue);
  color: #fff;
  border: 1px solid;
  border-color: var(--color-primary-blue);
}
.btn-custom-primary-new {
  background-color: var(--color-primary-new);
  color: #fff;
  border: none;
  border-radius: 6px;
}
.btn-custom-secondary-new {
  background-color: transparent;
  border: 1px solid #d0d5dd;
  border-radius: 6px;
}
.btn-custom-primary:disabled {
  background-color: #fff;
  color: #d8d8d8;
}
.btn-without-bg {
  background-color: transparent;
  color: var(--color-text);
  border: none;
  box-shadow: none;
  padding: 0 1rem;
}
.btn-custom-white {
  background-color: var(--color-background-btn);
  color: var(--color-text);
  border: 1px solid;
  border-color: var(--color-text);
}
.btn-custom-white:hover {
  color: var(--color-text);
}
.btn-wellet-secondary:disabled,
.btn-wellet-reject:disabled {
  color: var(--color-text-disabled);
  border-color: var(--color-text-disabled);
}
.btn-wellet-primary:disabled {
  background-color: #fff;
  color: #d8d8d8;
}
.btn-custom-white:disabled {
  color: var(--color-text-disabled);
  border-color: var(--color-text-disabled);
}
.btn-custom-white:hover:not([disabled]) {
  background-color: var(--hover);
}
.btn-custom-red {
  border: 1px solid;
  border-color: var(--border-color-red);
  background-color: var(--bg-red);
  color: var(--color-red);
}
.btn-custom-red:hover {
  border-color: #9a002e;
  background-color: #9a002e;
  color: var(--color-text-white);
}
.btn-custom-soldout {
  opacity: 0.5;
  text-decoration: line-through;
}
.btn-custom-black {
  background-color: #000;
  color: var(--color-text-white);
}
.btn-custom-black:hover {
  background-color: #000;
  color: var(--color-text-white);
}

.btn-custom-rejected,
.btn-custom-rejected:hover {
  border-radius: 5px;
  /* width: 126px; */
  font-size: 14px;
  border: 1px solid var(--border-color-red);
  background-color: var(--bg-red);
  color: var(--color-red);
}
.btn-custom-approved,
.btn-custom-approved:hover {
  border-radius: 5px;
  /* width: 126px; */
  font-size: 14px;
  border: 1px solid var(--border-color-green);
  background-color: var(--bg-green);
  color: var(--color-green);
}
.btn-outline {
  border: none !important;
  border-radius: 0.25rem !important;
  padding: 0 1rem !important;
  background-color: transparent !important;
  color: #333 !important;
}
.btn-outline:hover:not([disabled]) {
  transition: all 0.5s ease;
  background-color: #dad4d4 !important;
}
.clean-filter-btn {
  border-radius: 50px;
  padding: 0.2rem 1rem;
  height: 1.8rem !important;
}

@media (max-width: 768px) {
  .btn-custom-red:hover {
    border: 1px solid;
    border-radius: 5px;
    border-color: var(--border-color-red);
    min-height: 28px;
    background-color: var(--bg-red);
    color: var(--color-red);
    transition: all 0.3s ease-in-out;
  }
}

/* ---------- Form Styles----------*/
.error-content {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
.form-group {
  margin-bottom: 1rem;
}

.opacity {
  opacity: 1 !important;
}

/* ---------- Text Styles----------*/
.text-strikethrough {
  text-decoration: line-through;
}
.integer {
  white-space: nowrap;
}
.text-strikethrough .integer {
  color: var(--color-text-light);
  text-decoration: line-through;
}

/* ---------- Cards Styles----------*/
.card-popup {
  background-color: var(--color-background-app);
  border-radius: 0.25rem;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

/* ---------- Badge Styles----------*/
.confirmBadge,
.cancelBadge {
  height: 30px;
  width: 100%;
  max-width: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  filter: grayscale(100%);
  border: 1px solid var(--color-border-table);
  border-radius: 0.25rem;
}
.confirmBadge {
  background-color: var(--color-background-app);
}
label,
p {
  margin: 0 !important;
}

.footer-batch {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-left: 10px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #e1e1e1;
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.rdrInputRanges {
  display: none;
}

.rdrMonth {
  padding: 0 0.833em 1em 0.833em;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.rdrStartEdge {
  top: 1px;
}

.rdrEndEdge,
.rdrStartEdge {
  border-radius: 50%;
  background-color: var(--color-primary-new);
  height: 32.86px;
  top: 2px;
  left: 0px;
  right: 3px;
  width: 32.86px;
}

.rdrInRange {
  background-color: #f9fafb;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #000;
}

.rdrWeekDay {
  color: #000;
  font-weight: bold;
}

.rdrDayToday .rdrDayNumber span:after {
  background-color: var(--color-primary-new);
  content: none;
}
.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  left: 1px;
}
.rdrNextPrevButton {
  background-color: transparent;
}

.rdrNextPrevButton:hover {
  background-color: transparent;
}

.rdrPprevButton i {
  transform: rotate(135deg) !important;
}

.rdrNextPrevButton i {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg);
}

.rdrMonthName {
  color: #000;
  text-align: center;
  margin: auto;
}

.rdrCalendarWrapper.rdrDateRangeWrapper .rdrMonthAndYearPickers {
  display: none;
}

.rdrCalendarWrapper.rdrDateRangeWrapper .rdrMonths {
  margin-top: -2.7rem;
}

.rdrDayEndOfWeek .rdrStartEdge,
.rdrDayStartOfMonth .rdrStartEdge,
.rdrDayEndOfMonth .rdrStartEdge {
  border-radius: 50%;
  width: 32.86px;
}
.rdrMonthAndYearWrapper {
  height: auto;
  padding-top: 0;
}

.rdrCalendarWrapper.rdrDateRangeWrapper .rdrMonthAndYearWrapper {
  height: 60px;
  padding-top: 10;
}

.rdrDayStartOfWeek .rdrEndEdge {
  border-radius: 50%;
  width: 32.86px;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  top: 2px;
}

.rdrDayEndPreview:not(.card-popup .rdrDayEndPreview) {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-right-radius: 1.333em;
  border-bottom-right-radius: 1.333em;
  right: 2px;
}

.rdrEndEdge {
  right: 0;
  top: 2px;
}

/* WELLET CSS */
/*Colors*/
.font-weight-regular {
  font-weight: 400;
}

.color-body {
  color: var(--color-primary);
}

.dark {
  color: var(--color-dark);
}
.dark-grey {
  color: #475467;
}

.light-grey {
  color: var(--color-secondary-light);
}
.fs-16 {
  font-size: 16px;
}
.fs-15 {
  font-size: 15px;
}
.fs-14 {
  font-size: 14px;
}
.fs-12 {
  font-size: 12px;
}
.fs-10 {
  font-size: 10px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-25 {
  font-size: 25px;
}

.container-header {
  border-top: 1px solid #eaecf0;
  padding-bottom: 1.625rem; /* 26px */
  margin-bottom: 30px;
}

/* Maquetacion de Paginas */
.container-header.with-border {
  border: none;
  border-bottom: 1px solid #eaecf0;
}
.container-header.without-border {
  border: none;
}
.container-page {
  overflow-x: hidden;
}
.container-page .container-title {
  font-size: 2rem; /* 32px */
  font-weight: 600;
  line-height: 2.375rem; /* 38px */
  /* height: 2.375rem; */
}
.container-page .container-small-title {
  font-size: 0.813rem; /* 13px */
  font-weight: 400;
  line-height: 1.25rem; /* 20px */
  padding-bottom: 0.25rem; /* 4px */
}

.container-page .container-subtitle {
  font-size: 1.25rem; /* 20px */
  font-weight: 700;
  line-height: 2rem; /* 32px */
  padding-bottom: 1.25rem;
  margin-bottom: 1.875rem; /* 30px */
}
.container-page .container-data {
  margin-bottom: 0.625rem; /* 10px */
}
.container-page .container-label {
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 500;
  margin-bottom: 0.125rem; /* 2px */
}
.container-page .container-text {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
}
.container-page .container-section {
  width: 100%;
  margin-bottom: 1.875rem;
}

.btn-pagination-wellet {
  border: 1px solid #d0d5dd;
  border-radius: 0.5rem;
  padding: 0 1rem;
  background-color: #fff;
  font-weight: 600;
}
.btn-pagination-wellet:hover:not(:disabled) {
  background-color: #d0d5dd;
}
.btn-wellet {
  border: 1px solid #d0d5dd;
  border-radius: 0.25rem;
  padding: 0 1rem;
  height: 36px;
  line-height: 18px;
  background-color: #f9fafb;
  font-size: 14px;
  font-weight: 600;
}

.btn-wellet-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-primary-new);
  border-radius: 0.5rem;
  padding: 0 1rem;
  height: 36px;
  padding: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.btn-wellet-secondary,
.btn-wellet-reject {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
  border: 1px solid #d0d5dd;
  border-radius: 0.5rem;
  padding: 0 1rem;
  height: 36px;
  padding: 1rem;
  font-size: 14px;
  font-weight: 600;
  color: #344054;
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.pagination-text {
  color: #344054;
}

.line-height-1 {
  line-height: 1;
}

.wrap-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-wellet-reject {
  background-color: #d92d20;
  color: #fff;
  border: none;
}
.btn-wellet-secondary.bg-custom-light {
  background-color: #f9fafb;
}
.language-selector-flag {
  width: 32px;
  height: 32px;
  background-image: url(https://cdn1.wellet.fun/images/flags/sprite-flags-32x32.png);
  transform: scale(0.6);
  display: inline-flex;
}
.language-selector-flag-en,
.language-selector-flag-us {
  background-position: -224px -448px;
}
.language-selector-flag-ar {
  background-position: -384px -32px;
}
.language-selector-flag-mx {
  background-position: -64px -320px;
}
.language-selector-flag-pt {
  background-position: -480px -64px;
}
.language-selector-flag-ru {
  background-position: -64px -384px;
}
.language-selector-flag-cn {
  background-position: -448px -96px;
}
.language-selector-flag-de {
  background-position: -192px -128px;
}
.language-selector-flag-fr {
  background-position: -256px -160px;
}
.language-selector-flag-hi {
  background-position: -64px -224px;
}
.language-selector-flag-es {
  background-position: 0px -160px;
}

.avatar-container {
  position: relative;
  max-width: auto;
}
.verified-avatar {
  position: absolute;
  bottom: 0;
  right: 0;
}

.pointer {
  cursor: pointer;
}

.card-total-black,
.card-total-dark-grey {
  text-align: center;
  color: #d9d9d9;
  padding: 8px 2px;
  border-radius: 10px;
  border: 1px solid #7e7e7e;
  min-width: 100px;
  max-width: 210px;
}
.card-total-black {
  background: #000;
}
.card-total-dark-grey {
  background: #101828;
}
.iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--color-border-table);
  border-radius: 0.5rem;
  color: #344054;
}

.link-custom {
  color: var(--color-primary);
}
.link-custom:hover {
  color: var(--color-primary);
  text-decoration: underline;
}
.link-underline {
  color: #675dff;
  text-decoration: underline;
}
.link-underline:hover {
  color: #675dff;
  text-decoration: none;
}

.lh-1 {
  line-height: 1;
}

.mb-26 {
  margin-bottom: 1.625rem !important;
}

.margin-audit {
  margin-bottom: 2.1875rem !important;
}

.fs-lg {
  font-size: 1.125rem;
}
