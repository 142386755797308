td,
th,
tbdoy,
table:not(.showBorders),
thead tr {
  border-left: none !important;
  border-right: none !important;
  padding: 0 !important;
  /* border-right: 0 !important; */
}

.borderRadius {
  border-radius: 12px;
}

.padding-low {
  padding: 1rem 0.5rem !important;
}
.table thead .padding-th-low {
  padding: 0.25rem 0.5rem !important;
}
.padding-normal {
  padding: 1rem !important;
}
td:not(.rdp-cell, .p-0, .outline-td) {
  height: 30px;
  margin: 0 !important;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.header-grey {
  background-color: #f9fafb;
}
.header-white {
  background-color: #FFFFFF;
}

tr {
  border-top: 1px solid var(--color-border-table);
  height: 100%;
}

tr:last-child {
  border-bottom: none;
}

td {
  border: none !important;
}

th {
  border: none !important;
  text-align: left;
  white-space: nowrap; /* Evita saltos de línea */
  overflow: hidden; /* Oculta el texto que no cabe */
  text-overflow: ellipsis;
}

.table {
  color: var(--color-primary) !important;
}

.table thead {
  background-color: #fafafa;
  color: var(--color-secondary);
}

.table thead th:not(.padding-white-header) {
  padding: 0.25rem 1rem !important;
}

.padding-white-header {
  padding: 0.8rem !important;
}


.row-header.no-border {
  border-top: none !important;
}

.currency-column,
.check-column {
  width: 20px !important;
}
td .currencyCode {
  font-weight: 500;
  margin-left: 10px;
}
input[type='checkbox']:not(.toggle_modern),
.check {
  border-width: 0px !important;
  /* box-shadow: 0 4px 6px rgba(216, 222, 228, 0.5) !important; */
  border-radius: 8px !important;
}
.table-row {
  cursor: pointer;
  transition: background-color 0.3s ease;
  z-index: 0;
}
.table-row:hover:not(.not-hover, .outline-tr) {
  cursor: pointer;
  background-color: var(--hover);
  z-index: 0;
}

.not-hover {
  cursor: default;
}

.visible {
  opacity: 1;
  /* width: auto; */
  /* transition: opacity 0.3s ease, margin 0.3s ease; */
}

.hidden {
  opacity: 0;
  /* width: 0; */
  /* transition: opacity 0.3s ease, margin 0.3s ease; */
}

.td-btn-group {
  background-color: var(--color-background-app);
}

.table-row:hover .btn-right {
  border: 1px solid var(--color-border-table);
}
.btn-left,
.btn-center,
.btn-right {
  background-color: var(--color-background-app);
}
.btn-left:hover,
.btn-right:hover {
  border-color: var(--color-border-table-light) !important;
}
.btn-center:hover {
  border-color: var(--color-border-table) !important;
  border-left: 1px solid #3f51b5;
  border-right: 1px solid #3f51b5;
}

/* th:not(.commission-header) {
  padding: 0.5rem !important;
} */

.commission-row {
  border: none;
}
.commission-header {
  padding: 2px !important;
}
.commission-row td {
  padding: 1px !important;
}

.commission-row.even {
  background-color: #f5f5f5;
}
.commission-row.odd {
  background-color: #fafbfd;
}

.table-row-warning,
.table-row-warning:hover {
  background: #fffadf;
}
.table-row-warning td {
  color: #e29521 !important;
}
.table-row-expired:hover {
  background-color: unset !important;
}
.table-row-expired td {
  color: #c9c9c9 !important;
}
.table-row-cancelled td {
  cursor: pointer;
  color: #b3093c !important;
}

/* .table th,
.table td {
  white-space: nowrap;
}

.table th:last-child,
.table td:last-child {
  width: 100%;
} */

.border-container {
  border-radius: 12px;
  border: 1px solid #eaecf0;
}

.outline-header div {
  background-color: transparent !important;
  font-weight: 600;
  color: var(--color-primary) !important;
}

.outline-td {
  padding: 0.25rem 0.5rem 0.25rem 0.25rem !important;
  font-weight: 500;
}
.outline-td.primary {
  color: var(--color-primary) !important;
}
.outline-td.secondary {
  color: var(--color-secondary) !important;
}
.outline-tr {
  border: none !important;
  cursor: default;
}