.customCard {
  max-width: 200px;
  width: 100%;
  background-color: var(--color-primary-blue) !important;
  border: 1px solid !important;
  border-color: var(--color-btn-primary-text);
}
.text-custom-card {
  color: var(--color-btn-primary-text) !important;
}
@media (max-width: 600px) {
  .customCard {
    max-width: 150px;
  }
}
