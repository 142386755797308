.select-style {
  border-radius: 8px;
  background-color: var(--color-background-btn);
  padding: 2px;
  width: 100%;
  outline: 0;
  box-shadow: 0px 1px 2px 0px #1018280D;
  color: var(--color-primary);
}

/* Revisar porque no funciona el focus */
.select-style:focus {
  border: 1px solid var(--color-primary-blue) !important;
}

.input-icon + .select-style {
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;
}

.custom-option {
  gap: 5px;
  align-items: flex-start;
}

.custom-option input[type='checkbox'] {
  border-width: 0px !important;
  border-radius: 8px !important;
  box-shadow: none !important;
}

.multiValueEdit {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 4px;
  box-sizing: border-box;
  z-index: 99999;
}
.multiValueEdit:hover {
  cursor: pointer;
  color: var(--color-title);
  background-color: #cacaca;
}
.css-wsp0cs-MultiValueGeneric {
  color: var(--color-text) !important;
}
