.half-circle-progress {
  position: relative;
  text-align: center;
}
.barOverflow {
  /* Wraps the rotating .bar */
  position: relative;
  overflow: hidden; /* Comment this line to understand the trick */
  width: 160px;
  height: 80px; /* Half circle (overflow) */
}
.bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 160px;
  height: 160px; /* full circle! */
  border-radius: 50%;
  box-sizing: border-box;
  border: 16px solid #eee; /* half gray, */
  border-bottom-color: #7f56d9; /* half azure */
  border-right-color: #7f56d9;
}

.progress-percentage {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
}

.progress-legend {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
}
