.clipboard-container {
    position: relative;
}

.copied-text {
    font-size: 12px;
    position: absolute;
    bottom: -50px;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    padding: 5px 10px;
    border-radius: 4px;
    z-index: 1;
}

.clipboard-container.copied .copied-text {
    opacity: 1;
}